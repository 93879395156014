<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="600px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("uploadfile.name") }} <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35"
            >mdi-file-upload</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="mt-2" v-if="statusuploading === true">
          <v-btn
            elevation="1"
            fab
            x-small
            class="text-center ml-2"
            :color="$vuetify.theme.dark ? 'white' : color.theme"
            @click="cleardata()"
            :disabled="createprogress"
          >
            <v-icon
              :color="$vuetify.theme.dark ? '#989898' : 'white'"
              dark
              size="16"
              >keyboard_backspace</v-icon
            >
          </v-btn>
          <v-spacer></v-spacer>
          <v-chip outlined class="mr-2"
            >{{ $t("uploadfile.uploadingfile") + " " + length_start + " " }}
            {{
              length_start > 1 ? $t("uploadfile.files") : $t("uploadfile.file")
            }}</v-chip
          >
          <v-chip outlined class="mr-2"
            >{{ $t("uploadfile.success") + " " + length_success + " " }}
            {{
              length_success > 1
                ? $t("uploadfile.files")
                : $t("uploadfile.file")
            }}</v-chip
          >
          <v-chip outlined class="mr-2"
            >{{ $t("uploadfile.fail") + " " + length_fail + " " }}
            {{
              length_fail > 1 ? $t("uploadfile.files") : $t("uploadfile.file")
            }}</v-chip
          >
          <v-chip outlined class="mr-2"
            >{{ $t("uploadfile.duplicate") + " " + length_duplicate + " " }}
            {{
              length_duplicate > 1
                ? $t("uploadfile.files")
                : $t("uploadfile.file")
            }}</v-chip
          >
        </v-card-actions>
        <v-card-text v-if="statusuploading === false">
          <div>
            <v-layout>
              <v-checkbox
                v-model="checkhashtag"
                dense
                :label="$t('selectbutton.hashtag')"
                class="mt-2"
              ></v-checkbox>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mt-n2 ml-2"
                    color="#B7B7B7"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="cursor:pointer"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>{{ $t("information.hashtag") }}</span>
              </v-tooltip>
            </v-layout>
            <span
              style="font-size:13px"
              class="mt-0"
              v-show="checkEncrypt == true"
              >{{ $t("selectbutton.remark") }}</span
            >
            <v-layout>
              <v-checkbox
                v-model="checkEncrypt"
                dense
                :label="$t('selectbutton.encrypt')"
                class="mt-0"
              ></v-checkbox>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mt-n4 ml-2"
                    color="#B7B7B7"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="cursor:pointer"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>{{ $t("information.encrypt") }}</span>
              </v-tooltip>
            </v-layout>
          </div>
          <v-row v-if="checkhashtag === true">
            <v-col cols="9" sm="10" xs="9">
              <v-text-field
                v-model="hashtag"
                label="#hashtag"
                outlined
                dense
                :error-messages="hashtagErrors"
                @input="$v.hashtag.$touch()"
                @blur="$v.hashtag.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="2" xs="3" class="pt-3">
              <v-btn
                class="white--text"
                :color="color.theme"
                @click="addhashtag(), $v.hashtag.$reset()"
                :disabled="
                  hashtagErrors.length > 0
                    ? true
                    : false || hashtag.length === 0
                "
              >
                {{ $t("edithashtag.add") }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-chip
                v-for="(item, index) in datahashtag"
                :key="index"
                close
                class="ma-1"
                @click:close="deletehashtag(index)"
                >#{{ item }}</v-chip
              >
            </v-col>
          </v-row>
          <v-layout>
            <v-flex lg12 class="px-8">
              <div class="mb-4 mt-n12 text-right">
                <v-layout row wrap class="mx-1">
                  <v-flex lg12 v-if="totalstackfile <= 0">
                    <v-chip outlined color="#FFA000">
                      <p
                        style="font-size: 16px; font-weight: 600;"
                        class="mt-4"
                      >
                        {{ $t("direct_upload.nofilewaitupload") }}
                      </p>
                    </v-chip>
                  </v-flex>
                  <v-flex lg12 v-else>
                    <v-chip color="success">
                      <p style="font-size: 16px" class="mt-4">
                        <span style="font-weight: 600;">
                          {{ totalstackfile }}
                        </span>
                        {{
                          totalstackfile > 1
                            ? $t("direct_upload.files")
                            : $t("direct_upload.file")
                        }}
                      </p>
                    </v-chip>
                  </v-flex>
                </v-layout>
              </div>
              <!-- upload layout -->
              <div
                :style="
                  dropzoneuploadfiles +
                    'border-radius: 24px; position: relative;'
                "
                class="text-center mt-4 mx-auto"
                id="dropzoneuploadfiles"
                v-if="totalstackfile > 0"
              >
                <div
                  @click="ImportFiles"
                  id="inputuploadfiles"
                  style="opacity: 0;"
                >
                  <input
                    ref="importdoc"
                    type="file"
                    @change="fnStackFile"
                    multiple
                    class="d-none"
                  />
                </div>
                <v-overlay :value="stackfileloading" absolute>
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-overlay>
                <v-list
                  class="pt-6 mr-n4"
                  dense
                  :style="
                    layouthoveruploadfiles +
                      'z-index:3; overflow-y: scroll; height: 320px;'
                  "
                  id="layouthoveruploadfiles"
                >
                  <v-list-item
                    v-for="item in arraydataupload"
                    :key="item.id"
                    class="my-n4"
                    id="listStackFiles"
                  >
                    <v-list-item-action class="mr-4" id="listStackFiles">
                      <v-icon large :color="item.icon[1]" id="listStackFiles">{{
                        item.icon[0]
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content
                      class="mr-n2 pl-2 text-left"
                      id="listStackFiles"
                    >
                      <v-list-item-title
                        style="font-size: 14px;"
                        id="listStackFiles"
                      >
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        id="listStackFiles"
                        style="color:red;"
                        v-if="
                          item.errorduplicate === 'nonapi' ||
                            item.errorduplicate === 'api'
                        "
                      >
                        {{ $t("uploadfile.fileduplicate") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action id="listStackFiles" class="text-right">
                      <!-- ปุ่ม cancel upload -->
                      <v-icon
                        id="listStackFiles"
                        class="ml-2 pr-4"
                        @click="fnDeleteStackFile(item)"
                        color="red"
                        >mdi-close</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <div class="mb-n6" id="listStackFiles">
                  <span
                    id="titleTextUploadtitle"
                    class="mr-2"
                    style="font-size:16px;"
                    >{{ $t("uploadfile.clicktouploadnew") }}</span
                  >
                </div>
                <dir>
                  <span id="titleTextUploadsubtitle" style="font-size:14px;">{{
                    $t("uploadfile.maxsizeuploadtext")
                  }}</span>
                </dir>
              </div>
              <div
                v-else
                :style="uploadLayout + dropzoneuploadfiles"
                class="text-center mt-3 pt-12 pb-6  mx-auto"
                @click="ImportFiles"
                id="dropzoneuploadfiles"
              >
                <div
                  :style="layouthoveruploadfiles"
                  id="layouthoveruploadfiles"
                >
                  <input
                    ref="importdoc"
                    class="d-none"
                    type="file"
                    @change="fnStackFile"
                    multiple
                    style="display:none"
                  />
                  <v-img
                    max-width="240"
                    max-height="210"
                    class="mx-auto"
                    :src="require('@/assets/img/uploadfiles.png')"
                  />
                  <span id="titleTextUploadtitle" style="font-size:16px">{{
                    $t("uploadfile.clicktouploadnew")
                  }}</span>
                  <br />
                  <span id="titleTextUploadsubtitle" style="font-size:14px">{{
                    $t("uploadfile.maxsizeuploadtext")
                  }}</span>
                  <br />
                  <br />
                </div>
              </div>
            </v-flex>
          </v-layout>
          <!-- 15360MB = 15GB / 5120MB = 5GB -->
          <!-- <div @change="fileduplicate = '', checkfirstloopchoice = false">
            <file-pond
              name="filepond"
              ref="pond"
              allow-multiple="true"
              allow-drop="true"
              class-name="my-pond"
              allowRevert="false"
              :label-idle="$t('uploadfile.clicktoupload')"
              :labelFileProcessingError="$t('uploadfile.uploadfail')"
              :labelFileProcessingComplete="
              checkstatusduplicate === false
                ? $t('uploadfile.uploadsuccess')
                : $t('uploadfile.fileduplicate')
              "
              :labelFileLoading="$t('uploadfile.loadingfile')"
              :labelFileProcessing="$t('uploadfile.uploadingfile')"
              :labelFileProcessingAborted="$t('uploadfile.cancelingfile')"
              :labelFileProcessingRevertError="$t('uploadfile.reverterror')"
              :labelFileRemoveError="$t('uploadfile.removeerror')"
              :labelTapToRetry="$t('uploadfile.taptoretry')"
              :labelTapToCancel="$t('uploadfile.taptocancel')"
              :labelTapToUndo="$t('uploadfile.taptoundo')"
              v-bind:files="myFiles"
              :maxFileSize="
              dataCheckPackage.type === 'Business'
                ? dataCheckPackage.package
                  ? '5120MB'
                  : '500MB'
                : dataCheckPackage.package
                ? '5120MB'
                : '500MB'
              "
              maxParallelUploads="1"
              v-on:init="handleFilePondInit"
              :server="{ process, revert, restore, load, fetch }"
              :init="handleFilePondInit"
              :warning="handleWarning"
              :error="handleError"
              v-on:addfilestart="handleFilePondLoading"
              v-on:processfiles="handleFilePondProcessfiles"
              :disabled="createprogress"
            />
          </div> -->
          <!-- <v-layout row wrap align-center>
            <v-card-subtitle>if duplicate all file will auto make copy</v-card-subtitle>
            <v-checkbox v-model="selectcopyortag" label="Tag version"></v-checkbox>
          </v-layout> -->
        </v-card-text>
        <v-card-text v-else>
          <div>
            <v-divider></v-divider>
            <v-list two-line v-for="item in temparraydataupload" :key="item.id">
              <v-list-item>
                <v-list-item-action class="mr-4 ml-n4">
                  <v-icon large :color="item.icon[1]">{{
                    item.icon[0]
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content class="mr-n2">
                  <v-list-item-title style="font-size: 14px;">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px;">
                    <p>
                      {{ calculatesize(item.size) }}
                      <span
                        v-if="item.status === 'Uploading'"
                        class="float-right"
                        >{{
                          item.size > 524288000
                            ?  item.value > 95
                              ? 95
                              : Math.floor(item.value)
                            :  Math.floor(item.value)
                        }}
                        %</span
                      >
                      <span
                        v-else-if="item.status === 'success'"
                        style="color:green;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'fail'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'skip'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'cancel'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'duplicate'"
                        style="color:#fb8c00;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                    </p>
                  </v-list-item-subtitle>
                  <v-progress-linear
                    :color="item.colorupload"
                    class="mt-n4"
                    :value="item.value"
                  ></v-progress-linear>
                </v-list-item-content>
                <v-list-item-action
                  class="text-right mr-n6"
                  style="width: 90px"
                >
                  <!-- ปุ่ม replace -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicateForMobile('replace', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'primary'"
                    outlined
                    small
                    width="80px"
                  >
                    {{ $t("fileduplicate.filereplace") }}
                  </v-btn>
                  <!-- ปุ่ม copy -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicateForMobile('copy', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'success'"
                    outlined
                    small
                    width="80px"
                    class="mt-1"
                  >
                    {{ $t("fileduplicate.filecopy") }}
                  </v-btn>
                  <!-- ปุ่ม fileskip -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicateForMobile('', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'error'"
                    outlined
                    small
                    width="80px"
                    class="mt-1"
                  >
                    {{ $t("fileduplicate.fileskip") }}
                  </v-btn>
                  <!-- ปุ่ม reupload -->
                  <v-icon
                    v-if="item.status === 'fail' || item.status === 'cancel'"
                    @click="(reloadId = item.id),item.isButtonDisabled = true, createprogress = true, clearReUpload(item.id), checkUploading(item.id)"
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    class="ml-8"
                    >mdi-reload</v-icon
                  >
                  <!-- ปุ่ม cancel upload -->
                  <v-icon
                    v-if="
                      item.status === 'Uploading' || item.status === 'start'
                    "
                    class="ml-8"
                    @click="cancelUpload(item.id)"
                    :disabled=" item.isButtonDisabled === true ? item.isButtonDisabled :
                      item.size > 524288000
                        ? item.value > 95
                          ? true
                          : false
                        : false
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'error'"
                    >mdi-close</v-icon
                  >
                  <!-- ไอคอนอัปโหลดสำเร็จ -->
                  <v-icon
                    v-if="item.status === 'success'"
                    class="ml-8"
                    :color="$vuetify.theme.dark ? 'white' : 'success'"
                    >mdi-check</v-icon
                  >
                  <!-- ไอคอนยกเลิก -->
                  <v-icon
                    v-if="item.status === 'skip'"
                    class="ml-8"
                    :color="$vuetify.theme.dark ? 'white' : 'red'"
                    >mdi-cancel</v-icon
                  >
                  <!-- หมุนเส้น 3 -->
                  <v-progress-circular
                    indeterminate
                    class="ml-8"
                    :color="$vuetify.theme.dark ? 'white' : 'red'"
                    v-if="item.status === 'wait'"
                  ></v-progress-circular>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="
              checkstatusupload === false
                ? ($emit('close'), $emit('opendragselect'))
                : ($emit('closemobile'),
                  ((countupload = 0), (checkstatusupload = false)))
            "
            :disabled="
              createprogress || statusuploading === false
                ? statusuploading
                : length_start > 0
            "
            >{{ $t("uploadfile.buttonclose") }}</v-btn
          >
          <v-btn
            color="red"
            class="white--text"
            :disabled="createprogress || statusuploading || totalstackfile <= 0"
            @click="cleardata()"
          >
            {{ $t("direct_upload.clearfile") }}
          </v-btn>
          <v-btn
            color="#23526c"
            :disabled="
              totalstackfile === 0 ||
                createprogress ||
                statusuploading ||
                stackfileloading
            "
            @click="startingUpload()"
            class="white--text"
          >
            {{ $t("direct_upload.uploadfile") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="px-5 pt-4 pb-2">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{ $t("uploadfile.name") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="
                  checkstatusupload === false
                    ? ($emit('close'), $emit('opendragselect'))
                    : ($emit('closemobile'),
                      ((countupload = 0), (checkstatusupload = false)))
                "
                :disabled="createprogress"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <v-layout class="pt-4" v-if="statusuploading === true" row wrap>
            <v-flex xs2>
              <v-btn
                elevation="1"
                fab
                x-small
                class="text-center ml-4"
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="cleardata()"
                :disabled="createprogress"
              >
                <v-icon
                  :color="$vuetify.theme.dark ? '#989898' : 'white'"
                  dark
                  size="20"
                  >keyboard_backspace</v-icon
                >
              </v-btn>
            </v-flex>
            <v-flex xs10 class="text-right pr-2">
              <v-chip outlined
                >{{
                  $t("uploadfile.uploadingfile") + " " + length_start + " "
                }}
                {{
                  length_start > 1
                    ? $t("uploadfile.files")
                    : $t("uploadfile.file")
                }}</v-chip
              >
              <v-chip outlined
                >{{
                  $t("uploadfile.duplicate") + " " + length_duplicate + " "
                }}
                {{
                  length_duplicate > 1
                    ? $t("uploadfile.files")
                    : $t("uploadfile.file")
                }}</v-chip
              >
            </v-flex>
            <v-flex xs12 class="text-right pr-2">
              <v-chip outlined
                >{{ $t("uploadfile.success") + " " + length_success + " " }}
                {{
                  length_success > 1
                    ? $t("uploadfile.files")
                    : $t("uploadfile.file")
                }}</v-chip
              >
              <v-chip outlined
                >{{ $t("uploadfile.fail") + " " + length_fail + " " }}
                {{
                  length_fail > 1
                    ? $t("uploadfile.files")
                    : $t("uploadfile.file")
                }}</v-chip
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text
          :style="'max-height: ' + maxHeightOnMobile + '; height: auto;'"
        >
          <!-- hashtag && encrypt -->
          <div v-if="statusuploading === false">
            <v-checkbox
              class="checkboxStyle mb-n2"
              v-model="checkhashtag"
              dense
              :label="$t('selectbutton.hashtag')"
            ></v-checkbox>
            <v-row v-if="checkhashtag === true">
              <v-col cols="9">
                <v-text-field
                  height="35px"
                  class="textFieldClass"
                  v-model="hashtag"
                  label="#hashtag"
                  outlined
                  dense
                  :error-messages="hashtagErrors"
                  @input="$v.hashtag.$touch()"
                  @blur="$v.hashtag.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="ml-n3 pt-3">
                <v-btn
                  class="white--text"
                  :color="color.theme"
                  @click="addhashtag(), $v.hashtag.$reset()"
                  height="35px"
                  :disabled="
                    hashtagErrors.length > 0
                      ? true
                      : false || hashtag.length === 0
                  "
                >
                  {{ $t("edithashtag.add") }}</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-chip
                  v-for="(item, index) in datahashtag"
                  :key="index"
                  close
                  class="ma-1"
                  @click:close="deletehashtag(index)"
                  >#{{ item }}</v-chip
                >
              </v-col>
            </v-row>
            <v-checkbox
              class="checkboxStyle mb-n2 mt-0"
              v-model="checkEncrypt"
              dense
              :label="$t('selectbutton.encrypt')"
            ></v-checkbox>
            <span
              style="font-size:12px"
              class="mt-0 px-1"
              v-show="checkEncrypt == true"
              >{{ $t("selectbutton.remark") }}</span
            >
          </div>
          <div class="text-right" v-if="statusuploading === false">
            <v-layout row wrap class="mx-1">
              <v-flex lg12 v-if="totalstackfile <= 0">
                <v-chip outlined color="#FFA000">
                  <p style="font-size: 16px; font-weight: 600;" class="mt-4">
                    {{ $t("direct_upload.nofilewaitupload") }}
                  </p>
                </v-chip>
              </v-flex>
              <v-flex lg12 v-else>
                <v-chip color="success">
                  <p style="font-size: 16px" class="mt-4">
                    <span style="font-weight: 600;">
                      {{ totalstackfile }}
                    </span>
                    {{
                      totalstackfile > 1
                        ? $t("direct_upload.files")
                        : $t("direct_upload.file")
                    }}
                  </p>
                </v-chip>
              </v-flex>
            </v-layout>
          </div>
          <!-- upload layout -->
          <v-card-text
            :style="uploadLayoutMobile"
            class="text-center mt-3"
            v-if="statusuploading === false"
          >
            <div
              style="border-radius: 24px; position: relative;"
              class="text-center mt-4 mx-auto"
              v-if="totalstackfile > 0"
            >
              <div
                @click="ImportFiles"
                id="inputuploadfiles"
                style="opacity: 0;"
              >
                <input
                  ref="importdoc"
                  type="file"
                  @change="fnStackFile"
                  multiple
                  class="d-none"
                />
              </div>
              <v-overlay
                :value="stackfileloading"
                absolute
                class="mx-n4 mt-n3 mb-n5"
              >
                <v-progress-circular indeterminate></v-progress-circular>
              </v-overlay>
              <v-list
                class="pt-2 mx-n4 mt-n6"
                dense
                style="z-index:3; overflow-y: scroll; height: 320px;"
              >
                <v-list-item
                  v-for="item in arraydataupload"
                  :key="item.id"
                  class="my-n4"
                >
                  <v-list-item-action class="mr-4">
                    <v-icon large :color="item.icon[1]">{{
                      item.icon[0]
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content class="mr-n2 text-left">
                    <v-list-item-title style="font-size: 14px;">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="color:red;"
                      v-if="
                        item.errorduplicate === 'nonapi' ||
                          item.errorduplicate === 'api'
                      "
                    >
                      {{ $t("uploadfile.fileduplicate") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="text-right">
                    <!-- ปุ่ม cancel upload -->
                    <v-icon
                      class="ml-2"
                      @click="fnDeleteStackFile(item)"
                      color="red"
                      >mdi-close</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <div class="mb-0">
                <span
                  id="titleTextUploadtitle"
                  class="mr-2"
                  style="font-size:14px;"
                  >{{ $t("uploadfile.clicktouploadnew") }}</span
                >
              </div>
              <dir>
                <span id="titleTextUploadsubtitle" style="font-size:12px;">{{
                  $t("uploadfile.maxsizeuploadtext")
                }}</span>
              </dir>
            </div>
            <div
              v-else
              :style="uploadLayout"
              class="text-center mt-3 pt-6 pb-6  mx-auto"
              @click="ImportFiles"
            >
              <div>
                <input
                  ref="importdoc"
                  class="d-none"
                  type="file"
                  @change="fnStackFile"
                  multiple
                  style="display:none"
                />
                <v-img
                  id="listStackFiles"
                  max-width="220"
                  max-height="210"
                  class="mx-auto"
                  :src="require('@/assets/img/uploadfiles.png')"
                />
                <span id="titleTextUploadtitle" style="font-size:14px">{{
                  $t("uploadfile.clicktouploadnew")
                }}</span>
                <br />
                <span id="titleTextUploadsubtitle" style="font-size:12px">{{
                  $t("uploadfile.maxsizeuploadtext")
                }}</span>
                <br />
                <br />
              </div>
            </div>
          </v-card-text>
          <div v-else>
            <v-divider></v-divider>
            <v-list
              two-line
              v-for="item in temparraydataupload"
              :key="item.id"
              class="mt-n2"
            >
              <v-list-item>
                <v-list-item-action class="mr-4 ml-n4">
                  <v-icon large :color="item.icon[1]">{{
                    item.icon[0]
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content class="mr-n2">
                  <v-list-item-title style="font-size: 14px;">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px;">
                    <p>
                      {{ calculatesize(item.size) }}
                      <!-- <span v-if="item.status === 'start'" class="float-right"
                        >{{ item.value }} %</span
                      >
                      <span
                        v-else-if="item.status === 'success'"
                        style="color:green;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'fail'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'skip'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'cancel'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'duplicate'"
                        style="color:#fb8c00;"
                        class="float-right"
                        >{{ item.msg }}</span
                      > -->
                    </p>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span v-if="item.status === 'Uploading'" class="float-right"
                      >{{
                        item.size > 524288000
                          ?  item.value > 95
                            ? 95
                            : Math.floor(item.value)
                          : Math.floor(item.value)
                      }}
                      %</span
                    >
                    <span
                      v-else-if="item.status === 'success'"
                      style="color:green;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'fail'"
                      style="color:red;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'skip'"
                      style="color:red;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'cancel'"
                      style="color:red;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'duplicate'"
                      style="color:#fb8c00;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                  </v-list-item-subtitle>
                  <v-progress-linear
                    :color="item.colorupload"
                    class="mt-0"
                    :value="item.value"
                  >
                    <!-- <span v-if="item.status === 'start'" class="float-right"
                      >{{ item.value }} %</span
                    >
                    <span
                      v-else-if="item.status === 'success'"
                      style="color:white;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'fail'"
                      style="color:black;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'skip'"
                      style="color:white;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'cancel'"
                      style="color:black;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'duplicate'"
                      style="color:white;"
                      class="float-right"
                      >{{ item.msg }}</span
                    > -->
                  </v-progress-linear>
                </v-list-item-content>
                <v-list-item-action
                  class="text-right mr-n4"
                  style="width: 70px"
                >
                  <!-- ปุ่ม replace -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    small
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicateForMobile('replace', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'primary'"
                    outlined
                    width="60px"
                  >
                    {{ $t("fileduplicate.filereplace") }}
                  </v-btn>
                  <!-- ปุ่ม copy -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    small
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicateForMobile('copy', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'success'"
                    outlined
                    width="60px"
                    class="mt-1"
                  >
                    {{ $t("fileduplicate.filecopy") }}
                  </v-btn>
                  <!-- ปุ่ม fileskip -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    small
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicateForMobile('', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'error'"
                    outlined
                    width="60px"
                    class="mt-1"
                  >
                    {{ $t("fileduplicate.fileskip") }}
                  </v-btn>
                  <!-- ปุ่ม reupload -->
                  <v-icon
                    v-if="item.status === 'fail' || item.status === 'cancel'"
                    @click="
                        createprogress = true,
                        item.isButtonDisabled = true,
                        (reloadId = item.id),
                        clearReUpload(item.id),
                        checkUploading(item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    class="ml-7"
                    >mdi-reload</v-icon
                  >
                  <!-- ปุ่ม cancel upload -->
                  <v-icon
                    v-if="
                      item.status === 'Uploading' || item.status === 'start'
                    "
                    class="ml-7"
                    @click="cancelUpload(item.id)"
                    :disabled=" item.isButtonDisabled === true ? item.isButtonDisabled :
                      item.size > 524288000
                        ? item.value > 95
                          ? true
                          : false
                        : false
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'error'"
                    >mdi-close</v-icon
                  >
                  <!-- ไอคอนอัปโหลดสำเร็จ -->
                  <v-icon
                    v-if="item.status === 'success'"
                    class="ml-7"
                    :color="$vuetify.theme.dark ? 'white' : 'success'"
                    >mdi-check</v-icon
                  >
                  <!-- ไอคอนยกเลิก -->
                  <v-icon
                    v-if="item.status === 'skip'"
                    class="ml-7"
                    :color="$vuetify.theme.dark ? 'white' : 'red'"
                    >mdi-cancel</v-icon
                  >
                  <!-- หมุนเส้น 3 -->
                  <v-progress-circular
                    indeterminate
                    class="ml-7"
                    :color="$vuetify.theme.dark ? 'white' : 'red'"
                    v-if="item.status === 'wait'"
                  ></v-progress-circular>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </div>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            color="red"
            height="45px"
            width="35%"
            outlined
            style="font-size: 16px; font-weight: lighter;"
            :disabled="createprogress || statusuploading || totalstackfile <= 0"
            @click="cleardata()"
            class="mr-6"
          >
            {{ $t("direct_upload.clearfile") }}
          </v-btn>
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            class="white--text"
            color="#23526c"
            style="font-size: 16px; font-weight: lighter;"
            height="45px"
            width="35%"
            :disabled="
              totalstackfile === 0 ||
                createprogress ||
                statusuploading ||
                stackfileloading
            "
            @click="startingUpload()"
          >
            {{ $t("direct_upload.uploadfile") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <dialogalertduplicatefile
      :show="openalertduplicatefile"
      :listfileduplicate="listfileduplicate"
      @skip="setStatusFileDuplicate"
      @replace="setStatusFileDuplicate"
      @copy="setStatusFileDuplicate"
    ></dialogalertduplicatefile>
  </v-row>
</template>

<script>
import gbfGenerate from "@/globalFunctions/generateAuthorize";
// Import FilePond
import vueFilePond, { setOptions } from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
// // Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import sizeDataConverter from "@/globalFunctions/sizeDataConverter";
import { v4 as uuidv4 } from "uuid";
const dialogalertduplicatefile = () =>
  import("@/components/optional/dialog-alert-duplicate-file.vue");
export default {
  mixins: [validationMixin],
  validations: {
    foldername: { required },
    hashtag: { required },
  },
  components: { FilePond, dialogalertduplicatefile },
  props: [
    "show",
    "parentfolder",
    "switchapiupload",
    "remark",
    "oldactivefile",
    "departmentid",
  ],
  data: function() {
    return {
      hashtag: "",
      datahashtag: [],
      checkhashtag: false,
      checkEncrypt: false,
      valid: true,
      files: [],
      labelprocess: "",
      foldername: "",
      createprogress: false,
      uploadPercentage: 0,
      uploadProcess: "",
      uploadColor: "light-blue",
      uploadIndeterminate: false,
      listuploadfile: [],
      existingFiles: [],
      myFiles: [],
      selectcopyortag: false,
      selectingimportFiles: false,
      fileImport: null,
      percentfileuploading: 0,
      arraydataupload: [],
      statusuploading: false,
      reloadId: "",
      countupload: 0,
      countcancel: 0,
      fileduplicate: "",
      checkstatusduplicate: false,
      countduplicate: 0,
      openalertduplicatefile: false,
      listfileduplicate: [],
      checkstatusupload: false,
      newactionupload: false,
      checkfirstloopchoice: false,
      liststatusduplicate: [],
      totalstackfile: 0,
      temparraydataupload: [],
      stackfileloading: false,
      userdata: {},
      overdragfile: false,
      uniqueidfile: 0,
      part_per_send: 1,
      datachunkwaiting: [],
      chunkwaiting: [],
      checkopenchunk: false,
      conutindexupload: 0,
      count_cancel: 0,
    };
  },
  watch: {
    length_uploading(val) {
      if (this.shower === true && this.statusuploading === true) {
        if (this.temparraydataupload.length > 0) {
          console.log("this.checkopenchunk", this.checkopenchunk);
          console.log("val", val);
          console.log("this.checkopenchunk", this.conutindexupload);
          console.log("val", this.temparraydataupload.length);
          console.log(
            "val",
            this.temparraydataupload.length >= this.conutindexupload
          );
          if (this.temparraydataupload.length >= this.conutindexupload) {
            // let check = val.filter(item => item.size > 21474836480)
            // if (check.length > 0) {
            //   this.checkopenchunk = true;
            // }
            console.log(
              "valddddddddddddd",
              this.temparraydataupload.length >= this.conutindexupload
            );
            if (this.checkopenchunk === false) {
              let lastindex = this.conutindexupload;
              if (val.length < 5) {
                this.checkUploading(lastindex);
                this.conutindexupload += 1;
              }
            } else {
              console.log("this.checkopenchunk", this.checkopenchunk);
              let lastindex = this.datachunkwaiting.id;
              if (val.length <= 5) {
                if (
                  this.temparraydataupload[lastindex]["allchunk"].length >
                  this.temparraydataupload[lastindex]["numchunk"] + 1
                ) {
                  this.temparraydataupload[lastindex]["numchunk"] += 1;
                  if (this.checkEncrypt === true) {
                    this.fnOneUploadChunk(
                      lastindex,
                      this.temparraydataupload[lastindex]["numchunk"]
                    );
                  } else {
                    this.fnuploadChunks3(
                      this.temparraydataupload[lastindex]["datafile"],
                      lastindex,
                      this.temparraydataupload[lastindex]["numchunk"],
                      ""
                    );
                  }
                } else {
                  this.checkopenchunk = false;
                }
              }
            }
          } else {
            console.log("fefef 1");
            if (this.chunkwaiting.length > 0) {
              if (
                this.chunkwaiting.filter((item) => item.status === "Uploading")
                  .length > 0 ||
                this.chunkwaiting.filter((item) => item.status === "start")
                  .length > 0
              ) {
                let lastindex = this.datachunkwaiting.id;
                if (val.length <= 5) {
                  if (
                    this.temparraydataupload[lastindex]["allchunk"].length >
                    this.temparraydataupload[lastindex]["numchunk"] + 1
                  ) {
                    this.temparraydataupload[lastindex]["numchunk"] += 1;
                    if (this.checkEncrypt === true) {
                      this.fnOneUploadChunk(
                        lastindex,
                        this.temparraydataupload[lastindex]["numchunk"]
                      );
                    } else {
                      this.fnuploadChunks3(
                        this.temparraydataupload[lastindex]["datafile"],
                        lastindex,
                        this.temparraydataupload[lastindex]["numchunk"],
                        ""
                      );
                    }
                  } else {
                    this.checkopenchunk = false;
                  }
                }
              }
            }
          }
        }
      } else {
        this.conutindexupload = 0;
      }
    },
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataCheckPackage",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.addEventUploadfiles();
          // this.cleardata();
          this.$emit("closeDrag");
        } else {
          this.cleardata();
          this.removeEventUploadfiles();
        }
        console.log("this.show", this.show);
        return this.show;
      },
      set(value) {
        if (!value) {
          this.cleardata();
          this.removeEventUploadfiles();
          this.$emit("openDrag");
          this.$emit("close");
        }
      },
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.foldername.$dirty) return errors;
      !this.$v.foldername.required && errors.push("กรุณากรอกชื่อ โฟลเดอร์");
      return errors;
    },
    // length_complete() {
    //   return (
    //     this.temparraydataupload.filter(item => {
    //       return item.status === "fail" || item.status === "success" || item.status === "skip" || item.status === "cancel";
    //     }).length || this.arraydataupload.filter(item => {
    //       return item.status === "fail" || item.status === "success" || item.status === "skip" || item.status === "cancel";
    //     }).length || 0
    //   );
    // },
    length_uploading() {
      return (
        this.temparraydataupload
          .filter((item) => {
            return item.status === "Uploading";
          })
          .concat(
            this.chunkwaiting.filter((item) => {
              return item.status === "Uploading";
            })
          ) ||
        this.arraydataupload
          .filter((item) => {
            return item.status === "Uploading";
          })
          .concat(
            this.chunkwaiting.filter((item) => {
              return item.status === "Uploading";
            })
          ) ||
        0
      );
    },
    length_processing() {
      return (
        this.temparraydataupload.filter((item) => {
          return (
            item.status === "fail" ||
            item.status === "success" ||
            item.status === "duplicate" ||
            item.status === "skip" ||
            item.status === "cancel"
          );
        }).length ||
        this.arraydataupload.filter((item) => {
          return (
            item.status === "fail" ||
            item.status === "success" ||
            item.status === "duplicate" ||
            item.status === "skip" ||
            item.status === "cancel"
          );
        }).length ||
        0
      );
    },
    length_fail() {
      return (
        this.temparraydataupload.filter((item) => {
          return (
            item.status === "fail" ||
            item.status === "skip" ||
            item.status === "cancel"
          );
        }).length ||
        this.arraydataupload.filter((item) => {
          return (
            item.status === "fail" ||
            item.status === "skip" ||
            item.status === "cancel"
          );
        }).length ||
        0
      );
    },
    length_success() {
      return (
        this.temparraydataupload.filter((item) => {
          return item.status === "success";
        }).length ||
        this.arraydataupload.filter((item) => {
          return item.status === "success";
        }).length ||
        0
      );
    },
    length_duplicate() {
      return (
        this.temparraydataupload.filter((item) => {
          return item.status === "duplicate";
        }).length ||
        this.arraydataupload.filter((item) => {
          return item.status === "duplicate";
        }).length ||
        0
      );
    },
    length_start() {
      return (
        this.temparraydataupload.filter((item) => {
          return item.status === "start" || item.status === "Uploading";
        }).length ||
        this.arraydataupload.filter((item) => {
          return item.status === "start" || item.status === "Uploading";
        }).length ||
        0
      );
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    //style อักษรของ text ใหญ่ ใน upload layout ของ mobile
    titleTextUploadTitle() {
      if (this.resolutionScreen >= 500) {
        return "color:black; font-size: 18px; line-height: 24px;";
      } else {
        return "color:black; font-size: 14px; line-height: 24px;";
      }
    },
    //style อักษรของ text เล็ก ใน upload layout ของ mobile
    titleTextUploadSubtitle() {
      if (this.resolutionScreen >= 500) {
        return "color:red; font-size: 16px; line-height: 24px;";
      } else {
        return "color:red; font-size: 12px; line-height: 24px;";
      }
    },
    //style upload layout ของ mobile
    uploadLayoutMobile() {
      // return "background: #F0F6FB; border: 4px dashed #71B5D8; border-radius: 24px;";
      return (
        "background: " +
        this.color.BG +
        "; border: 4px dashed " +
        this.color.theme +
        "; border-radius: 24px; cursor: pointer;"
      );
    },
    uploadLayout() {
      if (this.createprogress === true) {
        return "border-radius: 24px; cursor: not-allowed;";
      } else {
        return "border-radius: 24px; cursor: pointer;";
      }
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    maxHeightOnMobile() {
      if (this.statusuploading === false) {
        return "700px";
      } else {
        return "400px";
      }
    },
    hashtagErrors() {
      let errors = [];
      if (this.$v.hashtag.$dirty === false) {
        return errors;
      } else if (this.$v.hashtag.required === false) {
        errors.push(this.$t("edithashtag.hashtagempty"));
        return errors;
      } else {
        let allow = true;
        let specialChar = [",", " ", "|"];

        for (let index = 0; index < specialChar.length; index++) {
          const element = specialChar[index];
          if (this.hashtag.includes(element)) {
            allow = false;
            break;
          } else {
            continue;
          }
        }
        if (allow === false) {
          errors.push(this.$t("edithashtag.nospecialcharacter"));
          return errors;
        } else {
          return errors;
        }
      }
    },
    layouthoveruploadfiles() {
      if (this.overdragfile === false) {
        return "background: none; border-radius: 25px; transition: background 150ms, opacity 150ms, height 175ms, width 175ms, border 300ms; opacity: 1; position: realtive;";
      } else {
        return "background: #aeb3b5; border-radius: 25px; transition: background 350ms, opacity 350ms, height 175ms, width 175ms, border 300ms; opacity: 0.4; position: realtive;";
      }
    },
    dropzoneuploadfiles() {
      if (this.overdragfile === false) {
        return (
          "border: 4px dashed " +
          this.color.theme +
          "; font: bold 42px Sarabun, system-ui, Tahoma, sans-serif; background: " +
          this.color.BG +
          "; transition: background 200ms, opacity 200ms, height 175ms, width 175ms, border 300ms;"
        );
      } else {
        return (
          "border: 4px dashed " +
          this.color.theme +
          "; font: bold 42px Sarabun, system-ui, Tahoma, sans-serif; background: #aeb3b5; transition: background 200ms, opacity 200ms, height 175ms, width 175ms, border 300ms;"
        );
      }
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 15) return string.substring(0, 15) + "...";
        else return string;
      }
    },
  },
  methods: {
    // ลูปการเก็บไฟล์ของการเลือกไฟล์
    async fnStackFile(e) {
      console.log("testlayout");
      this.temparraydataupload = [];
      this.statusuploading = false;
      // this.statusstorage = false;
      // this.createprogress = true;
      this.fileImport = e.target.files;
      let i = this.totalstackfile;
      let totalallfile = this.totalstackfile + this.fileImport.length;
      let numcreateformdata = 0;
      this.stackfileloading = true;
      for (i; i < totalallfile; i++) {
        let formData = await this.createPayloadUpload(i, numcreateformdata);
        this.arraydataupload[i].formData = formData;
        this.totalstackfile = this.totalstackfile + 1;
        numcreateformdata = numcreateformdata + 1;
        if (totalallfile === i + 1) {
          this.stackfileloading = false;
        }
      }
      // this.createprogress = false;
      // console.log("listitemduplicate this.arraydataupload", this.arraydataupload);
    },
    // ลูปการเก็บไฟล์ของการลากไฟล์
    async fnStackFileDrug(files) {
      this.temparraydataupload = [];
      this.statusuploading = false;
      // this.statusstorage = false;
      // this.createprogress = true;
      this.fileImport = files;
      let i = this.totalstackfile;
      let totalallfile = this.totalstackfile + this.fileImport.length;
      let numcreateformdata = 0;
      this.stackfileloading = true;
      for (i; i < totalallfile; i++) {
        let formData = await this.createPayloadUpload(i, numcreateformdata);
        this.arraydataupload[i].formData = formData;
        this.totalstackfile = this.totalstackfile + 1;
        numcreateformdata = numcreateformdata + 1;
        if (totalallfile === i + 1) {
          this.stackfileloading = false;
        }
      }
      // this.createprogress = false;
    },
    // ลบไฟล์ที่เก็บ
    fnDeleteStackFile(filedata) {
      var index = this.arraydataupload.indexOf(filedata);
      this.arraydataupload.splice(index, 1);
      this.totalstackfile = this.totalstackfile - 1;
      let listitemduplicate = this.arraydataupload.filter(
        (item) => item.name === filedata.name
      );
      // console.log("listitemduplicate", listitemduplicate);
      if (listitemduplicate.length > 0) {
        if (listitemduplicate[0].errorduplicate === "nonapi") {
          // console.log("listitemduplicate", listitemduplicate[0].errorduplicate);
          let indexduplicate = this.arraydataupload.indexOf(
            listitemduplicate[0]
          );
          this.arraydataupload[indexduplicate].errorduplicate = "not";
          this.arraydataupload[indexduplicate]["status"] = "start";
          this.arraydataupload[indexduplicate]["msg"] = "";
          this.arraydataupload[indexduplicate]["colorupload"] = "primary";
          this.arraydataupload[indexduplicate]["value"] = 0;
        }
      }
    },
    // ดักการรีโหลด
    forceDestroyEvent(status) {
      if (this.openalertduplicatefile === false) {
        if (status === true) {
          window.addEventListener("beforeunload", this.removeUser, true);
        } else {
          window.removeEventListener("beforeunload", this.removeUser, true);
        }
      }
    },
    // event
    removeUser(event) {
      event.returnValue = "Uploading folder";
    },
    // คำนวณขนาดไฟล์
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
    // กำหนดไอคอน
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "#0263d1"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png" ||
        parameter === "gif" ||
        parameter === "bmp"
      ) {
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else if (parameter === "r" || parameter === "folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    // ลบ event
    removeEventUploadfiles() {
      var self = this;
      console.log("removeEventUploadfiles");
      self.closeDragDrop = true;
      window.removeEventListener("dragenter", self.dragenteruploadfiles, true);
      window.removeEventListener("dragleave", self.dragleaveuploadfiles, true);
      window.removeEventListener("dragover", self.dragoveruploadfiles, true);
      window.removeEventListener("drop", self.dropuploadfiles, true);
      window
        .matchMedia("(orientation: portrait)")
        .removeListener(this.fnOrientation);
    },
    // เพิ่ม event dragdrop
    addEventUploadfiles() {
      console.log("addEventUploadfiles");
      if (this.resolutionScreen >= 500) {
        // ######### เช็คว่าหน้าจอเล็กไม่ให้ drag drop ได้ #########
        var self = this;
        self.closeDragDrop = false;
        window.addEventListener("dragenter", self.dragenteruploadfiles, true);
        window.addEventListener("dragleave", self.dragleaveuploadfiles, true);
        window.addEventListener("dragover", self.dragoveruploadfiles, true);
        window.addEventListener("drop", self.dropuploadfiles, true);
      }
    },
    // ลากเข้าไปโซน
    dragenteruploadfiles(e) {
      // console.log("dragenter dropzoneuploadfiles", e);
      if (
        e.target.id === "inputuploadfiles" ||
        e.target.id === "layouthoveruploadfiles" ||
        e.target.id === "dropzoneuploadfiles" ||
        e.target.id === "titleTextUploadtitle" ||
        e.target.id === "titleTextUploadsubtitle" ||
        e.target.id === "listStackFiles" ||
        e.target.className === "v-responsive__content"
      ) {
        if (this.createprogress === false) {
          // document.querySelector("#dropzoneuploadfiles").style.border =
          //   "4px dashed #448060";
          // document.querySelector("#dropzoneuploadfiles").style.background =
          //   "#aeb3b5";
          // document.querySelector("#layouthoveruploadfiles").style.background =
          //   "#aeb3b5";
          // document.querySelector("#layouthoveruploadfiles").style.opacity = "0.4";
          this.overdragfile = true;
        }
      }
    },
    // ลากเข้าเช็คทุกๆ 200 milliseconds
    dragoveruploadfiles(e) {
      // console.log("dragover dropzoneuploadfiles", e);
      if (
        e.target.id === "inputuploadfiles" ||
        e.target.id === "layouthoveruploadfiles" ||
        e.target.id === "dropzoneuploadfiles" ||
        e.target.id === "titleTextUploadtitle" ||
        e.target.id === "titleTextUploadsubtitle" ||
        e.target.id === "listStackFiles" ||
        e.target.className === "v-responsive__content"
      ) {
        if (this.createprogress === false) {
          e.preventDefault();
          e.stopPropagation();
          // document.querySelector("#dropzoneuploadfiles").style.border = "4px dashed " + this.color.theme;
          // document.querySelector("#dropzoneuploadfiles").style.background =
          //   "#aeb3b5";
          // document.querySelector("#layouthoveruploadfiles").style.background =
          //   "#aeb3b5";
          // document.querySelector("#layouthoveruploadfiles").style.opacity = "0.4";
          this.overdragfile = true;
        }
      }
    },
    // ลากออก
    dragleaveuploadfiles(e) {
      // console.log("dragleave dropzoneuploadfiles", e);
      if (this.createprogress === false) {
        e.preventDefault();
        // e.stopPropagation();
        // document.querySelector("#dropzoneuploadfiles").style.border = "4px dashed " + this.color.theme;
        // document.querySelector("#dropzoneuploadfiles").style.background = this.color.BG;
        // document.querySelector("#layouthoveruploadfiles").style.background = this.color.BG;
        // document.querySelector("#layouthoveruploadfiles").style.opacity = "1";
        this.overdragfile = false;
      }
    },
    // ลากแล้วปล่อยไฟล์
    async dropuploadfiles(e) {
      // console.log("drop dropzoneuploadfiles", e);
      if (
        e.target.id === "inputuploadfiles" ||
        e.target.id === "layouthoveruploadfiles" ||
        e.target.id === "dropzoneuploadfiles" ||
        e.target.id === "titleTextUploadtitle" ||
        e.target.id === "titleTextUploadsubtitle" ||
        e.target.id === "listStackFiles" ||
        e.target.className === "v-responsive__content"
      ) {
        if (this.createprogress === false) {
          e.preventDefault();
          e.stopPropagation();
          // document.querySelector("#dropzoneuploadfiles").style.border =
          //   "4px dashed #5fb588";
          // document.querySelector("#dropzoneuploadfiles").style.background =
          //   "#e8eff2";
          // document.querySelector("#layouthoveruploadfiles").style.background =
          //   "#e8eff2";
          // document.querySelector("#layouthoveruploadfiles").style.opacity = "1";
          let items = e.dataTransfer;
          this.overdragfile = false;
          this.fnStackFileDrug(items.files);
        }
      }
    },
    // เด้งให้เลือกไฟล์
    ImportFiles() {
      if (this.createprogress === false) {
        this.selectingImportFiles = true;
        this.checkfirstloopchoice = false;
        this.fileImport = null;
        this.$refs.importdoc.value = null;
        window.addEventListener(
          "focus",
          () => {
            this.selectingImportFiles = false;
          },
          { once: true }
        );
        this.$refs.importdoc.click();
      }
    },
    // ลูปการทำงานของการอัปโหลดของ mobile
    // async fnLoopUploadFile(e) {
    //   // สลับ layout
    //   this.statusuploading = true;
    //   this.createprogress = true;
    //   // เงื่อนไขการอัปโหลดอีกครั้ง
    //   if (this.reloadId !== "") {
    //     let i = this.reloadId;
    //     this.arraydataupload[i].status = "start";
    //     this.arraydataupload[i].msg = "";
    //     this.arraydataupload[i].colorupload = "primary";
    //     this.arraydataupload[i].value = 0;
    //     this.reloadId = "";
    //     let formData = this.createPayloadUpload(i);
    //     this.fnUploadFile(formData, i);
    //   }
    //   // เงื่อนไขการอัปโหลดจาก upload layout
    //   else {
    //     this.fileImport = e.target.files;
    //     for (let i = 0; i < this.fileImport.length; i++) {
    //       let formData = await this.createPayloadUpload(i);
    //       let responsecheckduplicate =
    //         this.checkfirstloopchoice === true
    //           ? ""
    //           : await this.checkDuplicateData(
    //               this.fileImport[i].name,
    //               this.parentfolder
    //             );
    //       if (responsecheckduplicate.status === "duplicate") {
    //         this.arraydataupload[i].status = "duplicate";
    //         this.arraydataupload[i].msg = this.$t("uploadfile.fileduplicate");
    //         this.arraydataupload[i].colorupload = "warning";
    //         this.arraydataupload[i].value = 99;
    //       } else {
    //         this.fnUploadFile(formData, i);
    //       }
    //     }
    //     this.createprogress = false;
    //   }
    // },
    // ทำงานตาม action ที่ผู้ใช้เลือกเมื่อไฟล์ซ้ำ (สำหรับโทรศัพท์)
    async setStatusFileDuplicateForMobile(checkaction, id) {
      let i = this.temparraydataupload.findIndex((item) => item.id === id);
      if (checkaction !== "") {
        // แทนที่
        if (checkaction === "replace") {
          this.temparraydataupload[i].fileduplicate = "create_tag_version";
        }
        // คัดลอก
        else if (checkaction === "copy") {
          this.temparraydataupload[i].fileduplicate = "create_copy";
        }
        this.reloadId = id;
        // let i = this.temparraydataupload.findIndex(
        //   (item) => item.id === this.reloadId
        // );
        // console.log("this.reloadId", this.reloadId);
        this.temparraydataupload[id].status = "start";
        this.temparraydataupload[id].msg = "";
        this.temparraydataupload[id].colorupload = "primary";
        this.temparraydataupload[id].value = 0;
        this.temparraydataupload[id].errorduplicate = "not";
        this.temparraydataupload[id].numchunk = 0;
        this.temparraydataupload[id].allchunk = [];
        this.temparraydataupload[id].parts = [];
        this.reloadId = "";
        let formDataDuplicate = await this.createPayloadUpload(id);
        this.temparraydataupload[id].formData = formDataDuplicate;
        console.log("status", this.temparraydataupload[id].status);
        this.checkUploading(id);
        // this.fnLoopUploadFile();
      } else {
        this.forceDestroyEvent(false);
        // console.log("test skip");
        this.temparraydataupload[i].status = "skip";
        this.temparraydataupload[i].msg = this.$t("fileduplicate.skipmsg");
        this.temparraydataupload[i].colorupload = "error";
        this.temparraydataupload[i].value = 100;
        if (this.length_processing === this.temparraydataupload.length) {
          this.createprogress = false;
        }
      }
    },
    clearReUpload(id) {
      this.temparraydataupload[id].status = "start";
      this.temparraydataupload[id].msg = "";
      this.temparraydataupload[id].colorupload = "primary";
      this.temparraydataupload[id].value = 0;
      this.temparraydataupload[id].errorduplicate = "not";
      this.temparraydataupload[id].numchunk = 0;
      this.temparraydataupload[id].allchunk = [];
      this.temparraydataupload[id].parts = [];
    },
    // สร้าง payload สำหรับอัปโหลดของ mobile
    async createPayloadUpload(i, numcreateformdata) {
      try {
        if (this.temparraydataupload.length !== 0) {
          // set data
          const formDataReload = new FormData();
          formDataReload.append("file", this.temparraydataupload[i].file);
          formDataReload.append("size_file", this.temparraydataupload[i].size);
          formDataReload.append("folder_id", this.parentfolder);
          formDataReload.append("user_id", this.dataUsername);
          formDataReload.append("department_id", this.departmentid);
          formDataReload.append("account_id", this.dataAccountId);
          formDataReload.append(
            "file_duplicate",
            this.temparraydataupload[i].fileduplicate
          );
          formDataReload.append("remark", this.remark || "");
          formDataReload.append(
            "old_version_file_id",
            this.oldactivefile || ""
          );
          formDataReload.append(
            "status_encrypt",
            this.checkEncrypt == true ? "Y" : "N"
          );
          return formDataReload;
        } else {
          // console.log("this.fileImport", this.fileImport);
          let typefile;
          if (!this.arraydataupload[i]) {
            typefile = this.fileImport[numcreateformdata].name.split(".");
            typefile = typefile[typefile.length - 1];
            let iconfile = this.seticon(typefile);
            // ปั้นดาต้าใหม่
            // typefile = this.seticon(typefile[typefile.length - 1]);
            let datafileupload = {};
            datafileupload["file"] = this.fileImport[numcreateformdata];
            datafileupload["icon"] = iconfile;
            datafileupload["name"] = this.fileImport[numcreateformdata].name;
            datafileupload["size"] = this.fileImport[numcreateformdata].size;
            datafileupload["canceltoken"] = null;
            datafileupload["id"] = this.uniqueidfile;
            datafileupload["chunkkey"] = "";
            datafileupload["numchunk"] = 0;
            datafileupload["allchunk"] = [];
            datafileupload["type"] = typefile;
            datafileupload["fileduplicate"] = "";
            datafileupload["listxhr"] = [];
            datafileupload["isButtonDisabled"] = false;
            datafileupload["checkcancelone"] = false;
            let fileName = this.arraydataupload.find(
              (element) => element.name === datafileupload["name"]
            );
            if (fileName !== undefined) {
              if (fileName.errorduplicate === "api") {
                datafileupload["errorduplicate"] = fileName.errorduplicate;
              } else {
                datafileupload["errorduplicate"] = "nonapi";
              }
              datafileupload["status"] = "duplicate";
              datafileupload["msg"] = this.$t("uploadfile.fileduplicate");
              datafileupload["colorupload"] = "warning";
              datafileupload["value"] = 99;
            } else {
              let responsecheckduplicate =
                // this.checkfirstloopchoice === true
                //   ? ""
                //   :
                await this.checkDuplicateData(
                  datafileupload["name"],
                  this.parentfolder
                );
              if (responsecheckduplicate.status === "duplicate") {
                datafileupload["errorduplicate"] = "api";
                datafileupload["status"] = "duplicate";
                datafileupload["msg"] = this.$t("uploadfile.fileduplicate");
                datafileupload["colorupload"] = "warning";
                datafileupload["value"] = 99;
              } else {
                datafileupload["errorduplicate"] = "not";
                datafileupload["status"] = "start";
                datafileupload["msg"] = "";
                datafileupload["colorupload"] = "primary";
                datafileupload["value"] = 0;
              }
            }
            this.arraydataupload.push(datafileupload);
            this.uniqueidfile += 1;
          }
          // this.createprogress = true;
          // set data
          const formData = new FormData();
          formData.append("file", this.arraydataupload[i].file);
          formData.append("size_file", this.arraydataupload[i].size);
          formData.append("folder_id", this.parentfolder);
          formData.append("user_id", this.dataUsername);
          formData.append("department_id", this.departmentid);
          formData.append("account_id", this.dataAccountId);
          formData.append(
            "file_duplicate",
            this.arraydataupload[i].fileduplicate
          );
          formData.append("remark", this.remark || "");
          formData.append("old_version_file_id", this.oldactivefile || "");
          formData.append(
            "status_encrypt",
            this.checkEncrypt == true ? "Y" : "N"
          );

          return formData;
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    // ลูปสำหรับการอัปโหลดไฟล์
    async startingUpload() {
      this.totalstackfile = 0;
      this.statusuploading = true;
      this.createprogress = true;
      this.temparraydataupload = [...this.arraydataupload];
      this.tempdatahashtag = [...this.datahashtag];
      this.checkhashtag = false;
      this.arraydataupload = [];
      // this.checkUploading(0);
    },
    async checkUploading(i) {
      this.temparraydataupload[i]
        ? (this.temparraydataupload[i].status = "Uploading")
        : "";
      // ต้องไม่มี error และต้องไม่ cencel
      if (
        this.temparraydataupload.length !== i &&
        this.temparraydataupload[i].errorduplicate === "not" &&
        this.temparraydataupload[i].status !== "cancel"
      ) {
        // ยิงเช็ค storage ยกเว้นหน้า directorysharefile
        if (this.$route.name !== "directorysharefile") {
          await this.fnCheckStorage();
        }
        // พื้นที่คงเหลือมากกว่าขนาดไฟล์หรือเป็นหน้า directorysharefile
        console.log(
          "this.userdata.remain_storage",
          this.userdata.remain_storage
        );
        console.log(
          "this.temparraydataupload[i].size",
          this.temparraydataupload[i].size
        );
        if (
          this.userdata.remain_storage > this.temparraydataupload[i].size ||
          this.$route.name === "directorysharefile"
        ) {
          // เช็คเงื่อนไขการเพิ่ม formData hashtag
          if (
            this.tempdatahashtag !== "" ||
            this.tempdatahashtag !== null ||
            this.tempdatahashtag !== undefined ||
            this.tempdatahashtag.length > 0
          ) {
            for (let i = 0; i < this.tempdatahashtag.length; i++) {
              this.temparraydataupload[i].formData.append(
                "hashtag",
                this.tempdatahashtag[i]
              );
            }
          } else {
            this.temparraydataupload[i].formData.append("hashtag", "");
          }
          // เพิ่ม status สำหรับหน้า directorysharefile
          if (this.$route.name === "directorysharefile") {
            this.temparraydataupload[i].formData.append("folder_from_share", "Y");
          }
          // เช็ค size แยกเส้นปกติกับ chunk
          if (this.checkEncrypt === true) {
            if (this.temparraydataupload[i].size > 5368709120) {
              this.datachunkwaiting = this.temparraydataupload[i];
              this.chunkwaiting = this.temparraydataupload[i].allchunk;
              console.log("this.chunkwaiting", this.chunkwaiting);
              this.checkopenchunk = true;
              this.fnOneUploadChunk(i, this.temparraydataupload[i]["numchunk"]);
            } else {
              this.fnUploadFile(this.temparraydataupload[i].formData, i);
            }
          } else {
            setTimeout(() => {
              this.temparraydataupload[i].isButtonDisabled = false;
            }, 3000);
            this.fnCheckingFileData(
              this.temparraydataupload[i].formData,
              i,
              this.temparraydataupload[i]["numchunk"]
            );
          }
        } else {
          // พื้นที่คงเหลือไม่เพียงพอ
          this.statusstorage = true;
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t(
            "dragdrop.yourspacenotenough"
          );
          this.temparraydataupload[i].colorupload = "error";
          this.createprogress = false;
        }
      } else {
        this.temparraydataupload[i]
          ? (this.temparraydataupload[i].status = "duplicate")
          : "";
      }
    },
    // ลูปสำหรับการอัปโหลดไฟล์
    async fnLoopUploadFile() {
      // สลับ layout
      this.totalstackfile = 0;
      this.statusuploading = true;
      this.createprogress = true;
      // เงื่อนไขการอัปโหลดอีกครั้ง
      if (this.reloadId !== "") {
        let i = this.temparraydataupload.findIndex(
          (item) => item.id === this.reloadId
        );
        console.log("this.reloadId", this.reloadId);
        this.temparraydataupload[i].status = "start";
        this.temparraydataupload[i].msg = "";
        this.temparraydataupload[i].colorupload = "primary";
        this.temparraydataupload[i].value = 0;
        this.reloadId = "";
        let formDataDuplicate = await this.createPayloadUpload(i);
        this.temparraydataupload[i].formData = formDataDuplicate;
        if (this.$route.name !== "directorysharefile") {
          await this.fnCheckStorage();
        }
        if (this.temparraydataupload[i].size > 5368709120) {
          await this.fnUploadChunk(i);
        } else {
          await this.fnUploadFile(this.temparraydataupload[i].formData, i);
        }
      }
      // เงื่อนไขการอัปโหลดจาก upload layout
      else {
        this.temparraydataupload = [...this.arraydataupload];
        this.tempdatahashtag = [...this.datahashtag];
        this.checkhashtag = false;
        this.arraydataupload = [];
        // if (this.resolutionScreen >= 500) {
        //   document.querySelector("#dropzoneuploadfiles").style.opacity = "0.4";
        // }
        let count_file = 0;
        for (let i = 0; i < this.temparraydataupload.length; i++) {
          // let formData = await this.createPayloadUpload(i);
          // let responsecheckduplicate =
          //   this.checkfirstloopchoice === true
          //     ? ""
          //     : await this.checkDuplicateData(
          //         this.temparraydataupload[i].name,
          //         this.parentfolder
          //       );
          // if (responsecheckduplicate.status === "duplicate") {
          //   this.temparraydataupload[i].status = "duplicate";
          //   this.temparraydataupload[i].msg = this.$t(
          //     "uploadfile.fileduplicate"
          //   );
          //   this.temparraydataupload[i].colorupload = "warning";
          //   this.temparraydataupload[i].value = 99;
          // } else {
          if (
            this.temparraydataupload[i].errorduplicate === "not" &&
            this.temparraydataupload[i].status !== "cancel"
          ) {
            if (this.$route.name !== "directorysharefile") {
              await this.fnCheckStorage();
            }
            count_file += 1;
            this.temparraydataupload[i].status = "Uploading";
            if (count_file < 5) {
              console.log("count_file if", count_file);
              if (this.temparraydataupload[i].size > 5368709120) {
                this.fnUploadChunk(i);
              } else {
                this.fnUploadFile(this.temparraydataupload[i].formData, i);
              }
            } else {
              console.log("count_file else", count_file);
              if (this.temparraydataupload[i].size > 5368709120) {
                await this.fnUploadChunk(i);
                count_file = 0;
              } else {
                await this.fnUploadFile(
                  this.temparraydataupload[i].formData,
                  i
                );
                count_file = 0;
              }
            }
          }
          // }
        }
        this.createprogress = false;
      }
    },
    async fnCheckingFileData(formData, i, numchunk) {
      try {
        if (
          this.userdata.remain_storage > this.temparraydataupload[i].size ||
          this.$route.name === "directorysharefile"
        ) {
          if (
            this.tempdatahashtag !== "" ||
            this.tempdatahashtag !== null ||
            this.tempdatahashtag !== undefined ||
            this.tempdatahashtag.length > 0
          ) {
            for (let i = 0; i < this.tempdatahashtag.length; i++) {
              formData.append("hashtag", this.tempdatahashtag[i]);
            }
          } else {
            formData.append("hashtag", "");
          }
          console.log(
            "this.temparraydataupload[i]",
            this.temparraydataupload[i]
          );
          const CancelToken = this.axios.CancelToken;
          this.source = CancelToken.source();
          this.temparraydataupload[i].canceltoken = this.source;
          console.log("formData", formData);
          let auth = await gbfGenerate.generateToken();
          let payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: this.parentfolder,
            file_duplicate: this.temparraydataupload[i].fileduplicate,
            filename: this.temparraydataupload[i].name,
            remark: "",
            size_file: this.temparraydataupload[i].size,
            department_id: this.departmentid,
            hashtag: this.tempdatahashtag,
          };
          if (this.$route.name === "directorysharefile") {
            payload.folder_from_share = "Y";
          }
          this.axios({
            method: "POST",
            url:
              process.env.VUE_APP_SERVICE_UPLOAD_API +
              "/api/v1/checking_file_data/upload",
            cancelToken: this.temparraydataupload[i].canceltoken.token,
            data: payload,
            headers: { Authorization: auth.code },
            // onUploadProgress: (e) => {
            //   if ((e.loaded / e.total) * 100 >= 95) {
            //     this.temparraydataupload[i]["value"] = 95;
            //     this.percentfileuploading = this.temparraydataupload[i][
            //       "value"
            //     ];
            //     this.percentfileuploading = this.temparraydataupload[i][
            //       "value"
            //     ];
            //   } else {
            //     this.temparraydataupload[i]["value"] = parseInt(
            //       (e.loaded / e.total) * 100
            //     );
            //     this.percentfileuploading = this.temparraydataupload[i][
            //       "value"
            //     ];
            //     this.percentfileuploading = this.temparraydataupload[i][
            //       "value"
            //     ];
            //   }
            // },
          })
            .then((response) => {
              if (response.data.status === "OK") {
                console.log("response.data.data", response.data.data);
                if (Object.keys(response.data.data.uploadChunk).length > 0) {
                  this.datachunkwaiting = this.temparraydataupload[i];
                  this.temparraydataupload[i]["datafile"]= response.data.data;
                  console.log("this.chunkwaiting", this.chunkwaiting);
                  console.log("this.checkopenchunk", this.checkopenchunk);
                  this.fnuploadChunks3(response.data.data, i, numchunk, "");
                } else {
                  this.fnUploads3(response.data.data, i);
                }
              } else if (
                response.data.errorMessage === "Your space not enough"
              ) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.yourspacenotenough"
                );
                this.temparraydataupload[i].colorupload = "error";
              } else if (
                response.data.errorMessage === "File Size More Than 5 GB"
              ) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.morefivegb"
                );
                this.temparraydataupload[i].colorupload = "error";
              } else {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.cannotdetailupload"
                );
                this.temparraydataupload[i].colorupload = "error";
              }

              if (this.temparraydataupload.length === this.length_processing) {
                // document.querySelector("#dropzoneuploadfiles").style.display = "auto";
                // document.querySelector("#dropzoneuploadfiles").style.opacity = "1";
                this.removeEventUploadfiles();
                this.addEventUploadfiles();
                this.createprogress = false;
              }
            })
            .catch((thrown) => {
              console.log(thrown);
              if (this.temparraydataupload[i].status !== "cancel" && this.count_cancel === 1) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "uploadfile.uploadfail"
                );
                this.temparraydataupload[i].colorupload = "error";
              }
              if (this.temparraydataupload.length === this.length_processing) {
                // document.querySelector("#dropzoneuploadfiles").style.display = "auto";
                // document.querySelector("#dropzoneuploadfiles").style.opacity = "1";
                this.removeEventUploadfiles();
                this.addEventUploadfiles();
                this.createprogress = false;
              }
            });
        } else {
          this.statusstorage = true;
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t(
            "dragdrop.yourspacenotenough"
          );
          this.temparraydataupload[i].colorupload = "error";
          this.createprogress = false;
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    async fnuploadChunks3(datafile, i, numchunk, checkreload) {
      try {
        if (numchunk === 0 && checkreload === "") {
          const CancelToken = this.axios.CancelToken;
          this.source = CancelToken.source();
          this.temparraydataupload[i].canceltoken = this.source;
          await this.create_chunk_new(this.temparraydataupload[i]);
          await this.generate_uuid(i);
          this.chunkwaiting = this.chunkwaiting.concat(this.temparraydataupload[i].allchunk);
          this.checkopenchunk = true;
          this.temparraydataupload[i]["parts"] = [];
          console.log("fkeopofep", this.chunkwaiting);
        }
        if (this.temparraydataupload[i]["allchunk"].length > 0) {
          if (this.temparraydataupload[i]["status"] === "Uploading") {
            try {
              let url = await this.fnGetPresignChunk(
                datafile,
                i,
                this.temparraydataupload[i]["allchunk"][numchunk]["PartNumber"]
              );
              this.temparraydataupload[i]["allchunk"][numchunk]["status"] ===
                "Uploading";
              let onechunkwaiting = this.chunkwaiting.filter(item => item.id === this.temparraydataupload[i]["id"]);
              let indexwatingchunk = this.chunkwaiting.indexOf(onechunkwaiting[numchunk])
              let result
              console.log("url", url);
              if (url !== undefined) {
                this.chunkwaiting[indexwatingchunk].status = "Uploading";
                result = await this.makeRequest(
                  "PUT",
                  url,
                  i,
                  numchunk
                );
              }
              console.log("result", result);
              if (result.data.status === 200) {
                let headers = await this.parseResponseHeaders(result.headers);
                headers.etag = headers.etag.replace('"', "");
                headers.etag = headers.etag.replace('"\ ', "");
                headers.etag = headers.etag.replace('"', "");
                let datachunk = {
                  PartNumber: this.temparraydataupload[i]["allchunk"][numchunk][
                    "PartNumber"
                  ],
                  ETag: headers.etag,
                };
                this.temparraydataupload[i]["parts"].push(datachunk);
                console.log(
                  "this.temparraydataupload[i]",
                  this.temparraydataupload[i]["allchunk"][numchunk]["parts"]
                );
                this.temparraydataupload[i]["allchunk"][numchunk]["status"] =
                  "Success";
                this.chunkwaiting[indexwatingchunk].status = "Success";
                this.percentfileuploading = this.temparraydataupload[i]["value"];
                let countchunksuccess = this.temparraydataupload[i][
                  "allchunk"
                ].filter((item) => item.status === "Success").length;
                if (
                  countchunksuccess === this.temparraydataupload[i]["allchunk"].length
                ) {
                  await this.fnInsertFileData(datafile, i, "chunk");
                  if (this.chunkwaiting.filter((item) => item.status === "Uploading").length == 0) {
                    this.chunkwaiting = [];
                    this.checkopenchunk = false;
                  }
                }
              } else {
                setTimeout(() => {
                  this.fnuploadChunks3(datafile, i, numchunk, "reload")
                }, 5000);
              //   this.temparraydataupload[i].status = "fail";
              //   this.temparraydataupload[i].msg = this.$t(
              //     "dragdrop.cannotdetailupload"
              //   );
              //   this.temparraydataupload[i].colorupload = "error";
              //   this.chunkwaiting = [];
              //   this.checkopenchunk = false;
              }
            } catch (error) {
              console.log(error);
              setTimeout(() => {
                this.fnuploadChunks3(datafile, i, numchunk, "reload")
              }, 5000);
            }
          }
        }
      } catch (error) {
        console.log(error);
        if (this.temparraydataupload[i].status !== "cancel" && this.count_cancel !== 1) {
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t("uploadfile.uploadfail");
          this.temparraydataupload[i].colorupload = "error";
        }
        if (this.temparraydataupload.length === this.length_processing) {
          this.removeEventUploadfiles();
          this.addEventUploadfiles();
          this.createprogress = false;
        }
        this.checkopenchunk = false;
      }
    },
    async makeRequest(method, url, i, numchunk) {
      // รับค่า blob data จาก showfileupload
      let data = this.temparraydataupload[i]["allchunk"][numchunk]["blob"];
      
      // สร้าง XMLHttpRequest object
      const xhr = new XMLHttpRequest();
      const self = this;
      
      // เพิ่ม XMLHttpRequest object นี้ไปใน listxhr ของ showfileupload
      this.temparraydataupload[i]["listxhr"].push(xhr);

      // คำนวณเปอร์เซ็นต์ที่แต่ละ request จะได้รับ
      const len_file = Math.ceil(this.temparraydataupload[i]["allchunk"].length / this.part_per_send);
      const chunkProgress = 100 / len_file;

      // ตัวแปรสำหรับเก็บ progress ของ chunk ปัจจุบัน
      let currentProgress = 0;

      // Return a new promise
      return new Promise(function(resolve, reject) {
        xhr.open(method, url, true);
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            // คำนวณเปอร์เซ็นต์ของ progress
            let percentComplete = (event.loaded / event.total) * chunkProgress;
            
            // อัพเดตค่า self.temparraydataupload[i]["value"] โดยใช้ค่าที่แตกต่างจาก previous progress
            self.temparraydataupload[i]["value"] += percentComplete - currentProgress;
            
            // อัพเดต currentProgress สำหรับ chunk ปัจจุบัน
            currentProgress = percentComplete;
          }
        };
        xhr.onload = function() {
          if (xhr.status >= 200 && xhr.status < 300) {
            let responseHeaders = xhr.getAllResponseHeaders();
            resolve({ headers: responseHeaders, data: xhr });
          } else {
            reject(xhr.statusText);
          }
        };

        xhr.onerror = function() {
          if (xhr.status === 0) {
            // Handle network error (e.g., ERR_INTERNET_DISCONNECTED)
            reject("ERR_INTERNET_DISCONNECTED");
          } else {
            reject(xhr.statusText);
          }
        };

        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status === 0) {
            // Handle network error (e.g., ERR_INTERNET_DISCONNECTED)
            reject("ERR_INTERNET_DISCONNECTED");
          }
        };
        xhr.send(data);
      });
    },
    parseResponseHeaders(headerString) {
      const headers = {};
      const headerPairs = headerString.split("\r\n");
      headerPairs.forEach(function(headerPair) {
        const index = headerPair.indexOf(":");
        if (index > 0) {
          const key = headerPair.substring(0, index).trim();
          const value = headerPair.substring(index + 1).trim();
          headers[key] = value;
        }
      });
      return headers;
    },
    async fnGetPresignChunk(datafile, i, partnumber) {
      try {
        let payload = {
          bucket_name: datafile.uploadChunk.bucket_name,
          object_name: datafile.uploadChunk.object_name,
          upload_id: datafile.uploadChunk.upload_id,
          part_number: partnumber,
        };
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/generate_presigned_url/upload/chunks",
          cancelToken: this.temparraydataupload[i]["canceltoken"]["token"],
          data: payload,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {},
        });
        if (result.data.status === "OK") {
          return result.data.data.url;
        } else if (result.data.errorMessage === "Your space not enough") {
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t(
            "dragdrop.yourspacenotenough"
          );
          this.temparraydataupload[i].colorupload = "error";
          // this.chunkwaiting = [];
          this.checkopenchunk = false;
          return "Your space not enough";
        } else if (result.data.errorMessage === "File Size More Than 10 GB") {
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t("dragdrop.morefivegb");
          this.temparraydataupload[i].colorupload = "error";
          // this.chunkwaiting = [];
          this.checkopenchunk = false;
          return "File Size More Than 10 GB";
        } else {
          // this.temparraydataupload[i].status = "fail";
          // this.temparraydataupload[i].msg = this.$t(
          //   "dragdrop.cannotdetailupload"
          // );
          // this.temparraydataupload[i].colorupload = "error";
          // this.chunkwaiting = [];
          // this.checkopenchunk = false;
          return undefined;
        }
      } catch (error) {
        console.log(error);
        if (this.temparraydataupload[i].status !== "cancel" && this.count_cancel === 1) {
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t("uploadfile.uploadfail");
          this.temparraydataupload[i].colorupload = "error";
          this.checkopenchunk = false;
        } else {
          return undefined;
        }
        if (this.temparraydataupload.length === this.length_processing) {
          this.removeEventUploadfiles();
          this.addEventUploadfiles();
          this.createprogress = false;
          this.checkopenchunk = false;
        }
      }
    },
    async fnUploads3(datafile, i) {
      let auth = await gbfGenerate.generateToken();
      const formData = new FormData();
      formData.append("policy", datafile.presigned_url.fields.policy);
      formData.append("signature", datafile.presigned_url.fields.signature);
      formData.append(
        "AWSAccessKeyId",
        datafile.presigned_url.fields.AWSAccessKeyId
      );
      formData.append("key", datafile.presigned_url.fields.key);
      formData.append("file", this.temparraydataupload[i].file);
      this.axios({
        method: "POST",
        url: datafile.presigned_url.url,
        cancelToken: this.temparraydataupload[i].canceltoken.token,
        data: formData,
        headers: {
          Authorization: auth.code,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => {
          if ((e.loaded / e.total) * 100 >= 95) {
            this.temparraydataupload[i]["value"] = 99;
            this.percentfileuploading = this.temparraydataupload[i][
              "value"
            ];
            this.percentfileuploading = this.temparraydataupload[i][
              "value"
            ];
          } else {
            this.temparraydataupload[i]["value"] = parseInt(
              (e.loaded / e.total) * 100
            );
            this.percentfileuploading = this.temparraydataupload[i]["value"];
            this.percentfileuploading = this.temparraydataupload[i]["value"];
          }
        },
      })
        .then((response) => {
          if (response.status === 204) {
            this.temparraydataupload[i].status = "wait";
            this.fnInsertFileData(datafile, i, "nochunk");
          } else {
            this.temparraydataupload[i].status = "fail";
            this.temparraydataupload[i].msg = this.$t(
              "dragdrop.cannotdetailupload"
            );
            this.temparraydataupload[i].colorupload = "error";
          }
          if (this.temparraydataupload.length === this.length_processing) {
            this.removeEventUploadfiles();
            this.addEventUploadfiles();
            this.createprogress = false;
          }
        })
        .catch((thrown) => {
          console.log(thrown);
          if (this.temparraydataupload[i].status !== "cancel" && this.count_cancel === 1) {
            this.temparraydataupload[i].status = "fail";
            this.temparraydataupload[i].msg = this.$t("uploadfile.uploadfail");
            this.temparraydataupload[i].colorupload = "error";
          }
          if (this.temparraydataupload.length === this.length_processing) {
            this.removeEventUploadfiles();
            this.addEventUploadfiles();
            this.createprogress = false;
          }
        });
    },
    async fnInsertFileData(datafile, i, status) {
      let auth = await gbfGenerate.generateToken();
      let payload = {
        account_id: datafile.account_id,
        archive_file: datafile.archive_file,
        business_id: datafile.business_id,
        data_type: datafile.data_type,
        department_id: datafile.department_id,
        file_id: datafile.file_id,
        file_id_copied: datafile.file_id_copied,
        filename: datafile.filename,
        folder_id: datafile.folder_id,
        hashtag: datafile.hashtag,
        remark: "",
        size_file: datafile.size_file,
        user_id: datafile.user_id,
        file_duplicate: this.temparraydataupload[i].fileduplicate,
        folder_from_share: datafile.folder_from_share,
      };
      if (status === "chunk") {
        payload.complete_file_chunk = {
          bucket_name: datafile.uploadChunk.bucket_name,
          object_name: datafile.uploadChunk.object_name,
          upload_id: datafile.uploadChunk.upload_id,
          parts: this.temparraydataupload[i]["parts"].sort(
            (a, b) => a.PartNumber - b.PartNumber
          ),
        };
      }
      this.axios({
        method: "POST",
        url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/v1/insert_file_data",
        cancelToken: this.temparraydataupload[i].canceltoken.token,
        data: payload,
        headers: { Authorization: auth.code },
      })
        .then((response) => {
          if (response.data.status === "OK") {
            // สถานะเคยอัปโหลดสำเร็จ
            this.checkstatusupload = true;
            this.temparraydataupload[i].status = "success";
            this.temparraydataupload[i].msg = this.$t(
              "uploadfile.uploadsuccess"
            );
            this.temparraydataupload[i].colorupload = "success";
            this.temparraydataupload[i].value = 100;
            this.countupload += 1;
          } else {
            this.temparraydataupload[i].status = "fail";
            this.temparraydataupload[i].msg = this.$t(
              "dragdrop.cannotdetailupload"
            );
            this.temparraydataupload[i].colorupload = "error";
          }
          if (this.temparraydataupload.length === this.length_processing) {
            // document.querySelector("#dropzoneuploadfiles").style.display = "auto";
            // document.querySelector("#dropzoneuploadfiles").style.opacity = "1";
            this.removeEventUploadfiles();
            this.addEventUploadfiles();
            this.createprogress = false;
          }
        })
        .catch((thrown) => {
          console.log(thrown);
          if (this.temparraydataupload[i].status !== "cancel" && this.count_cancel === 1) {
            this.temparraydataupload[i].status = "fail";
            this.temparraydataupload[i].msg = this.$t("uploadfile.uploadfail");
            this.temparraydataupload[i].colorupload = "error";
          }
          if (this.temparraydataupload.length === this.length_processing) {
            // document.querySelector("#dropzoneuploadfiles").style.display = "auto";
            // document.querySelector("#dropzoneuploadfiles").style.opacity = "1";
            this.removeEventUploadfiles();
            this.addEventUploadfiles();
            this.createprogress = false;
          }
        });
    },
    // เรียกใช้ api สำหรับอัปโหลด
    async fnUploadFile(formData, i) {
      try {
        if (
          this.userdata.remain_storage > this.temparraydataupload[i].size ||
          this.$route.name === "directorysharefile"
        ) {
          console.log("this.tempdatahashtag", this.tempdatahashtag);
          if (
            this.tempdatahashtag !== "" ||
            this.tempdatahashtag !== null ||
            this.tempdatahashtag !== undefined ||
            this.tempdatahashtag.length > 0
          ) {
            for (let i = 0; i < this.tempdatahashtag.length; i++) {
              formData.append("hashtag", this.tempdatahashtag[i]);
            }
          } else {
            formData.append("hashtag", "");
          }
          console.log(
            "this.temparraydataupload[i]",
            this.temparraydataupload[i]
          );
          if (this.$route.name === "directorysharefile") {
            this.temparraydataupload[i].formData.append("folder_from_share", "Y");
          }
          const CancelToken = this.axios.CancelToken;
          this.source = CancelToken.source();
          this.temparraydataupload[i].canceltoken = this.source;
          console.log("formData", formData);
          let auth = await gbfGenerate.generateToken();
          this.axios({
            method: "POST",
            url:
              process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/upload_files_v3",
            // "http://192.168.73.163:8813/api/upload_files_v3",
            cancelToken: this.temparraydataupload[i].canceltoken.token,
            data: this.temparraydataupload[i].formData,
            headers: { Authorization: auth.code },
            onUploadProgress: (e) => {
              if ((e.loaded / e.total) * 100 >= 95) {
                this.temparraydataupload[i]["value"] = 95;
                this.percentfileuploading = this.temparraydataupload[i][
                  "value"
                ];
                this.percentfileuploading = this.temparraydataupload[i][
                  "value"
                ];
              } else {
                this.temparraydataupload[i]["value"] = parseInt(
                  (e.loaded / e.total) * 100
                );
                this.percentfileuploading = this.temparraydataupload[i][
                  "value"
                ];
                this.percentfileuploading = this.temparraydataupload[i][
                  "value"
                ];
              }
            },
          })
            .then((response) => {
              if (response.data.status === "OK") {
                // สถานะเคยอัปโหลดสำเร็จ
                this.checkstatusupload = true;
                this.temparraydataupload[i].status = "success";
                this.temparraydataupload[i].msg = this.$t(
                  "uploadfile.uploadsuccess"
                );
                this.temparraydataupload[i].colorupload = "success";
                this.temparraydataupload[i].value = 100;
                this.countupload += 1;
              } else if (
                response.data.errorMessage === "Your space not enough"
              ) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.yourspacenotenough"
                );
                this.temparraydataupload[i].colorupload = "error";
              } else if (
                response.data.errorMessage === "File Size More Than 5 GB"
              ) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.morefivegb"
                );
                this.temparraydataupload[i].colorupload = "error";
              } else {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.cannotdetailupload"
                );
                this.temparraydataupload[i].colorupload = "error";
              }

              if (this.temparraydataupload.length === this.length_processing) {
                // document.querySelector("#dropzoneuploadfiles").style.display = "auto";
                // document.querySelector("#dropzoneuploadfiles").style.opacity = "1";
                this.removeEventUploadfiles();
                this.addEventUploadfiles();
                this.createprogress = false;
              }
            })
            .catch((thrown) => {
              console.log(thrown);
              if (this.temparraydataupload[i].status !== "cancel" && this.count_cancel === 1) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "uploadfile.uploadfail"
                );
                this.temparraydataupload[i].colorupload = "error";
              }
              if (this.temparraydataupload.length === this.length_processing) {
                // document.querySelector("#dropzoneuploadfiles").style.display = "auto";
                // document.querySelector("#dropzoneuploadfiles").style.opacity = "1";
                this.removeEventUploadfiles();
                this.addEventUploadfiles();
                this.createprogress = false;
              }
            });
        } else {
          this.statusstorage = true;
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t(
            "dragdrop.yourspacenotenough"
          );
          this.temparraydataupload[i].colorupload = "error";
          this.createprogress = false;
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    async fnOneUploadChunk(_index, numchunk) {
      try {
        if (numchunk === 0) {
          const CancelToken = this.axios.CancelToken;
          this.source = CancelToken.source();
          this.temparraydataupload[_index].canceltoken = this.source;
          await this.create_chunk(this.temparraydataupload[_index]);
          await this.generate_uuid(_index);
        }
        let payload;
        let res_ = await this.fn_check_payload_file(_index);
        payload = res_.msg;
        await this.fn_uploadfile_chunk(payload, _index, numchunk);
        // if (res_chunk.status === "ER") {
        //   break;
        // }
        // if (i === this.temparraydataupload[_index]["allchunk"].length - 1) {
        //   let res_p = await this.fn_check_payload_file_merge(_index);
        //   payload_merge = res_p.msg;
        //   let res_n;
        //   res_n = await this.fn_uploadfile_merge(payload_merge, _index);
        //   return new Promise((resolve) => {
        //     setTimeout(() => {
        //       resolve({ status: res_n.status, msg: res_n.msg });
        //     }, 1500);
        //   });
        // }
      } catch (error) {
        console.log(error);
        if (this.temparraydataupload[_index].status !== "cancel" && this.count_cancel === 1) {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t(
            "uploadfile.uploadfail"
          );
          this.temparraydataupload[_index].colorupload = "error";
        }
        if (this.temparraydataupload.length === this.length_processing) {
          this.removeEventUploadfiles();
          this.addEventUploadfiles();
          this.createprogress = false;
        }
        this.checkopenchunk = false;
      }
    },
    async fnUploadChunk(_index) {
      try {
        let payload;
        await this.create_chunk(this.temparraydataupload[_index]);
        await this.generate_uuid(_index);

        for (
          let i = 0;
          i < this.temparraydataupload[_index]["allchunk"].length;
          i++
        ) {
          this.temparraydataupload[_index]["numchunk"] = i;
          let res_ = await this.fn_check_payload_file(_index);
          payload = res_.msg;
          let res_chunk = await this.fn_uploadfile_chunk(payload, _index);
          if (res_chunk.status === "ER") {
            break;
          }
          if (i === this.temparraydataupload[_index]["allchunk"].length - 1) {
            let res_p = await this.fn_check_payload_file_merge(_index);
            payload_merge = res_p.msg;
            let res_n;
            res_n = await this.fn_uploadfile_merge(payload_merge, _index);
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve({ status: res_n.status, msg: res_n.msg });
              }, 1500);
            });
          }
        }
      } catch (error) {
        console.log(error);
        if (this.temparraydataupload[_index].status !== "cancel" && this.count_cancel === 1) {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t(
            "uploadfile.uploadfail"
          );
          this.temparraydataupload[_index].colorupload = "error";
        }
        if (this.temparraydataupload.length === this.length_processing) {
          // document.querySelector("#dropzoneuploadfiles").style.display = "auto";
          // document.querySelector("#dropzoneuploadfiles").style.opacity = "1";
          this.removeEventUploadfiles();
          this.addEventUploadfiles();
          this.createprogress = false;
        }
      }
    },
    async fn_uploadfile_chunk(payload, _index, i) {
      try {
        this.temparraydataupload[_index]["allchunk"][i]["status"] ===
          "Uploading";
        this.chunkwaiting[i].status = "Uploading";
        console.log("this.chunkwaiting[i]", this.chunkwaiting[i]);
        let len_file = Math.ceil(
          this.temparraydataupload[_index]["allchunk"].length /
            this.part_per_send
        );
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/upload_files_chunk",
          // "http://192.168.73.163:8813/api/upload_files_chunk",
          cancelToken: this.temparraydataupload[_index]["canceltoken"]["token"],
          data: payload,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {},
        });
        if (result.data.status === "OK") {
          this.temparraydataupload[_index]["value"] += Math.ceil(
            100 / (len_file + 1)
          );
          this.temparraydataupload[_index]["allchunk"][i]["status"] = "Success";
          this.chunkwaiting[i].status = "Success";
          this.percentfileuploading = this.temparraydataupload[_index]["value"];
          // return new Promise((resolve) => {
          //   setTimeout(() => {
          //     resolve({
          //       status: "OK",
          //       msg: "Upload File Success",
          //       type: "file",
          //     });
          //   }, 1500);
          // });
          let countchunksuccess = this.temparraydataupload[_index][
            "allchunk"
          ].filter((item) => item.status === "Success").length;
          if (
            countchunksuccess ===
            this.temparraydataupload[_index]["allchunk"].length
          ) {
            let payload_merge;
            let res_p = await this.fn_check_payload_file_merge(_index);
            payload_merge = res_p.msg;
            let res_n;
            res_n = await this.fn_uploadfile_merge(payload_merge, _index);
            this.checkopenchunk = false;
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve({ status: res_n.status, msg: res_n.msg });
              }, 1500);
            });
          }
        } else if (result.data.errorMessage === "Your space not enough") {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t(
            "dragdrop.yourspacenotenough"
          );
          this.temparraydataupload[_index].colorupload = "error";
          this.chunkwaiting = [];
          this.checkopenchunk = false;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: "OK",
                msg: "Your space not enough",
                type: "file",
              });
            }, 1500);
          });
        } else if (result.data.errorMessage === "File Size More Than 10 GB") {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t("dragdrop.morefivegb");
          this.temparraydataupload[_index].colorupload = "error";
          this.chunkwaiting = [];
          this.checkopenchunk = false;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: "OK",
                msg: "File Size More Than 10 GB",
                type: "file",
              });
            }, 1500);
          });
        } else {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t(
            "dragdrop.cannotdetailupload"
          );
          this.temparraydataupload[_index].colorupload = "error";
          this.chunkwaiting = [];
          this.checkopenchunk = false;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: "OK",
                msg: result.data.errorMessage,
                type: "file",
              });
            }, 1500);
          });
        }
      } catch (err) {
        console.log(err);
        if (this.temparraydataupload[_index].status !== "cancel"  && this.count_cancel === 1) {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t(
            "uploadfile.uploadfail"
          );
          this.temparraydataupload[_index].colorupload = "error";
        }
        if (this.temparraydataupload.length === this.length_processing) {
          // document.querySelector("#dropzoneuploadfiles").style.display = "auto";
          // document.querySelector("#dropzoneuploadfiles").style.opacity = "1";
          this.removeEventUploadfiles();
          this.addEventUploadfiles();
          this.createprogress = false;
        }
        this.chunkwaiting = [];
        this.checkopenchunk = false;
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              status: "ER",
              msg: err,
              type: "file",
            });
          }, 1500);
        });
      }
    },
    fn_check_payload_file(_index) {
      const formData = new FormData();
      formData.append(`account_id`, this.dataAccountId);
      formData.append("folder_id", this.parentfolder);
      formData.append(
        `file_duplicate`,
        this.temparraydataupload[_index]["fileduplicate"]
      );
      formData.append(`file_name`, this.temparraydataupload[_index]["name"]);
      formData.append(`size_file`, this.temparraydataupload[_index]["size"]);
      formData.append(
        `file_part`,
        this.temparraydataupload[_index]["name"] +
          `.part_${this.temparraydataupload[_index]["numchunk"]}`
      );
      formData.append(`key`, this.temparraydataupload[_index]["chunkkey"]);
      if (this.$route.name === "directorysharefile") {
        formData.append("folder_form_share", "Y");
      }
      for (let i = 0; i < this.part_per_send; i++) {
        if (
          this.temparraydataupload[_index]["allchunk"][
            i +
              this.temparraydataupload[_index]["numchunk"] * this.part_per_send
          ].blob
        ) {
          formData.append(
            `blob`,
            this.temparraydataupload[_index]["allchunk"][
              i + this.temparraydataupload[_index]["numchunk"]
            ].blob
          );
        }
      }
      return new Promise((resolve) => {
        resolve({ status: "file", msg: formData });
      });
    },
    async fn_check_payload_file_merge(_index) {
      const formData = new FormData();
      formData.append(`account_id`, this.dataAccountId);
      formData.append(`user_id`, this.dataUsername);
      formData.append("folder_id", this.parentfolder);
      formData.append(
        `file_duplicate`,
        this.temparraydataupload[_index]["fileduplicate"]
      );
      formData.append(`file_name`, this.temparraydataupload[_index]["name"]);
      formData.append(`remark`, "");
      formData.append(
        `len_file`,
        Math.ceil(
          this.temparraydataupload[_index]["allchunk"].length /
            this.part_per_send
        )
      );
      formData.append(`key`, this.temparraydataupload[_index]["chunkkey"]);
      if (this.$route.name === "directorysharefile") {
        formData.append("folder_form_share", "Y");
      }
      return new Promise((resolve) => {
        resolve({ status: "file", msg: formData });
      });
    },
    async fn_uploadfile_merge(payload_merge, _index) {
      try {
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/merge_files_chunk",
          // "http://192.168.73.163:8813/api/merge_files_chunk",
          data: payload_merge,
          headers: { Authorization: auth.code },
        });
        if (result.data.status === "OK") {
          // สถานะเคยอัปโหลดสำเร็จ
          this.checkstatusupload = true;
          this.temparraydataupload[_index].status = "success";
          this.temparraydataupload[_index].msg = this.$t(
            "uploadfile.uploadsuccess"
          );
          this.temparraydataupload[_index].colorupload = "success";
          this.temparraydataupload[_index].value = 100;
          this.countupload += 1;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: "OK",
                msg: "Upload File Success",
                type: "file",
              });
            }, 1500);
          });
        } else if (result.data.errorMessage === "Your space not enough") {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t(
            "dragdrop.yourspacenotenough"
          );
          this.temparraydataupload[_index].colorupload = "error";
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: "ER",
                msg: "Your space not enough",
                type: "file",
              });
            }, 1500);
          });
        } else if (result.data.errorMessage === "File Size More Than 10 GB") {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t("dragdrop.morefivegb");
          this.temparraydataupload[_index].colorupload = "error";
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: "ER",
                msg: "File Size More Than 10 GB",
                type: "file",
              });
            }, 1500);
          });
        } else {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t(
            "dragdrop.cannotdetailupload"
          );
          this.temparraydataupload[_index].colorupload = "error";
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: "ER",
                msg: result.data.errorMessage,
                type: "file",
              });
            }, 1500);
          });
        }
      } catch (error) {
        console.log(error);
        if (this.temparraydataupload[_index].status !== "cancel" && this.count_cancel === 1) {
          this.temparraydataupload[_index].status = "fail";
          this.temparraydataupload[_index].msg = this.$t(
            "uploadfile.uploadfail"
          );
          this.temparraydataupload[_index].colorupload = "error";
        }
        if (this.temparraydataupload.length === this.length_processing) {
          this.removeEventUploadfiles();
          this.addEventUploadfiles();
          this.createprogress = false;
        }
      }
    },
    async create_chunk(item) {
      var size = 104857600;
      var chunks = 0;
      chunks = Math.ceil(item.size / size);
      for (let i = 0; i < chunks; i++) {
        let payload_blob = {
          status: "start",
          blob: item.file.slice(
            i * size,
            Math.min(i * size + size, item.size),
            item.type
          ),
        };
        item.allchunk.push(payload_blob);
        // item.allchunk.push(
        //   item.file.slice(
        //     i * size,
        //     Math.min(i * size + size, item.size),
        //     item.type
        //   )
        // );
      }
    },
    async create_chunk_new(item) {
      var size = 1073741824;
      // var size = 104857600;
      var chunks = 0;
      chunks = Math.ceil(item.size / size);
      for (let i = 0; i < chunks; i++) {
        let payload_blob = {
          status: "start",
          blob: item.file.slice(
            i * size,
            Math.min(i * size + size, item.size)
            // "text/plain"
          ),
          PartNumber: i + 1,
          parts: {},
          id: item.id
        };
        item.allchunk.push(payload_blob);
      }
    },
    generate_uuid(_index) {
      let myuuid = "";
      myuuid = uuidv4();
      this.temparraydataupload[_index]["chunkkey"] = myuuid;
    },
    // เช็คพื้นที่ข้อมูล
    async fnCheckStorage() {
      try {
        let payload = {
          account_id: this.dataAccountId,
          convert_storage: "True",
        };
        let auth = await gbfGenerate.generateToken();
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS_CHECK_STORAGE +
            "/api/check_used_storage",
          payload,
          { headers: { Authorization: auth.code } }
        );
        if (response.data.status === "OK") {
          let remain = await sizeDataConverter.convertStorageToByte(
            response.data.result[0].remain_storage
          );
          this.userdata.remain_storage = remain;
        } else {
          console.log("res", response);
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    // สำหรับยกเลิกการอัปโหลดของ mobile
    cancelUpload(i) {
      this.temparraydataupload[i].status = "cancel";
      this.temparraydataupload[i].msg = this.$t("dragdrop.cancelupload");
      this.temparraydataupload[i].colorupload = "error";
      if (this.temparraydataupload[i].canceltoken !== "") {
        this.temparraydataupload[i].canceltoken.cancel();
      }
      if (this.temparraydataupload[i]["listxhr"].length > 0) {
        const xhrList = this.temparraydataupload[i]["listxhr"];
        xhrList.forEach(xhr => xhr.abort());
      }
      if (this.temparraydataupload[i].size > 1073741824) {
        let itemchunk = this.chunkwaiting.filter(el => el.id = this.temparraydataupload[i].id);
        for (let i = 0; i < itemchunk.length; i++) {
          let indexwatingchunk = this.chunkwaiting.indexOf(itemchunk[i]);
          this.chunkwaiting[indexwatingchunk].status === "cancel";
          if (itemchunk.length > i + 1) {
            if (this.chunkwaiting.filter(check => check.status === "Uploading").length === 0) {
              this.datachunkwaiting = [];
              this.chunkwaiting = [];
              this.checkopenchunk = false;
            }
          }
        }
      }
      if (this.temparraydataupload.length === this.length_processing) {
        this.removeEventUploadfiles();
        this.addEventUploadfiles();
        this.createprogress = false;
      }
    },
    // ฟังชันก์ยกเลิกการอัปโหลด
    cancel_upload_all() {
      this.count_cancel = 1;
      for (let i = 0; i < this.temparraydataupload.length; i++) {
        if (this.temparraydataupload[i]["status"] === "start" || this.temparraydataupload[i]["status"]=== "Uploading") {
          if (this.temparraydataupload[i].canceltoken !== "") {
            this.temparraydataupload[i].canceltoken.cancel();
          }
          this.temparraydataupload[i].status = "cancel";
          this.temparraydataupload[i].msg = this.$t("dragdrop.cancelupload");
          this.temparraydataupload[i].colorupload = "error";
          if (this.temparraydataupload[i]["listxhr"].length > 0) {
            const xhrList = this.temparraydataupload[i]["listxhr"];
            xhrList.forEach(xhr => xhr.abort());
          }
        }
      }
      if (this.temparraydataupload.length === this.length_processing) {
        this.removeEventUploadfiles();
        this.addEventUploadfiles();
        this.createprogress = false;
      }
    },
    deletehashtag(index) {
      this.datahashtag.splice(index, 1);
      // console.log("this.datahashtag", this.datahashtag);
    },
    addhashtag() {
      this.datahashtag.push(this.hashtag);
      // console.log("this.datahashtag", this.datahashtag);
      this.hashtag = "";
    },
    // handleFilePondInit: function() {
    //   console.log("FilePond has initialized");
    // },
    // handleFilePondProcessfiles(error, file) {
    //   // console.log("FilePond succesfully processed file ");
    //   this.createprogress = false;
    //   if (this.countduplicate > 0) {
    //     this.countduplicate = 0;
    //     this.openalertduplicatefile = true;
    //     this.createprogress = false;
    //   } else {
    //     this.forceDestroyEvent(false);
    //   }
    //   this.$nextTick();
    // },
    // handleFilePondLoading() {
    //   this.createprogress = true;
    // },
    // ทำงานตาม action ที่ผู้ใช้เลือกเมื่อไฟล์ซ้ำ
    setStatusFileDuplicate(selectedduplicate, fileduplicate) {
      let listnewshowupload = selectedduplicate;
      for (let i = 0; i < selectedduplicate.length; i++) {
        let indexduplicate = this.listfileduplicate.indexOf(
          selectedduplicate[i]
        );
        this.listfileduplicate.splice(indexduplicate, 1);
      }
      // แทนที่หรือคัดลอก
      if (fileduplicate !== "") {
        // get file ที่อยู่ในลิสการอัปโหลด
        let allfileupload = this.$refs.pond.getFiles();
        let filereupload = [];
        // เลือกเฉพาะไฟล์ที่ซ้ำ
        for (let i = 0; i < listnewshowupload.length; i++) {
          let duplicactefileupload = allfileupload.filter(
            (fileupload) => listnewshowupload[i].name === fileupload.filename
          );
          this.liststatusduplicate.push({
            name: listnewshowupload[i].name,
            fileduplicate: fileduplicate,
          });
          filereupload.push(duplicactefileupload[0]);
        }
        // นำเข้า lib เพื่ออัปโหลดอีกครั้ง
        this.$refs.pond.processFiles(filereupload);
        if (this.checkfirstloopchoice === false) {
          this.checkfirstloopchoice = true;
          this.countupload = 0;
          this.countduplicate = 0;
        }
        this.createprogress = true;
      }
      if (this.listfileduplicate.length === 0) {
        this.openalertduplicatefile = false;
      }
    },
    // ฟังก์ชันเช็คไฟล์ซ้ำ
    async checkDuplicateData(name, folder_id) {
      let payload = {
        folder_id: folder_id,
        data_type: "file",
        data_name: name,
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/check_duplicate_data",
          data: payload,
          headers: { Authorization: auth.code },
        });
        if (response.data.status === "OK") {
          if (response.data.message === "duplicate filename") {
            return new Promise(async (resolve, reject) => {
              resolve({
                status: "duplicate",
                msg: response.data.message,
              });
            });
          } else {
            return new Promise(async (resolve, reject) => {
              resolve({
                status: "upload success",
                msg: response.data.message,
              });
            });
          }
        } else {
          return new Promise(async (resolve, reject) => {
            resolve({
              status: "upload denied",
              msg: response.data.errorMessage,
            });
          });
        }
      } catch (err) {
        return new Promise(async (resolve, reject) => {
          resolve({
            status: "upload denied",
            msg: err,
          });
        });
      }
    },
    // ฟังก์ชันอัปโหลดไฟล์ของ filepond
    // async process(fieldName, file, metadata, load, error, progress, abort) {
    //   if (this.countupload === 0) {
    //     await this.forceDestroyEvent(false);
    //     await this.forceDestroyEvent(true);
    //   }
    //   this.checkstatusduplicate = false;
    //   console.log("process start");
    //   this.$emit("checkclosedialog");
    //   // ส่งไปเช็คไฟล์ซ้ำ
    //   let statusduplicate =
    //     this.checkfirstloopchoice === true
    //       ? ""
    //       : await this.checkDuplicateData(file.name, this.parentfolder);
    //   if (statusduplicate.status === "duplicate") {
    //     this.checkstatusduplicate = true;
    //     this.countduplicate++;
    //     let type = file.name.split(/\.(?=[^\.]+$)/);
    //     type = this.seticon(type[1]);
    //     let datafileduplicate = {
    //       file: file,
    //       icon: type,
    //       name: file.name,
    //     };
    //     this.listfileduplicate.push(datafileduplicate);
    //     load();
    //   } else {
    //     // set data
    //     let filestatus = this.liststatusduplicate.filter(
    //       (fileupload) => file.name === fileupload.name
    //     );
    //     const formData = new FormData();
    //     formData.append("file", file);
    //     formData.append("size_file", file.size);
    //     formData.append("folder_id", this.parentfolder);
    //     formData.append("user_id", this.dataUsername);
    //     formData.append("department_id", this.departmentid);
    //     formData.append("account_id", this.dataAccountId);
    //     formData.append(
    //       "file_duplicate",
    //       filestatus[0] === undefined ? "" : filestatus[0].fileduplicate
    //     );
    //     formData.append("remark", this.remark || "");
    //     formData.append("old_version_file_id", this.oldactivefile || "");
    //     formData.append(
    //       "status_encrypt",
    //       this.checkEncrypt == true ? "Y" : "N"
    //     );
    //     // เช็ค hashtag
    //     if (
    //       this.datahashtag !== "" ||
    //       this.datahashtag !== null ||
    //       this.datahashtag !== undefined
    //     ) {
    //       for (let i = 0; i < this.datahashtag.length; i++) {
    //         formData.append("hashtag", this.datahashtag[i]);
    //       }
    //     } else {
    //       formData.append("hashtag", "");
    //     }
    //     if (this.$route.name === "directorysharefile") {
    //       this.temparraydataupload[i].formData.append("folder_from_share", "Y");
    //     }
    //     const CancelToken = this.axios.CancelToken;
    //     const source = CancelToken.source();
    //     let auth = await gbfGenerate.generateToken();
    //     this.axios({
    //       method: "POST",
    //       url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/upload_files_v3",
    //       cancelToken: source.token,
    //       data: formData,
    //       headers: { Authorization: auth.code },
    //       onUploadProgress: (e) => {
    //         if ((e.loaded / e.total) * 100 >= 95) {
    //           progress(e.lengthComputable, (e.total / 100) * 99, e.total);
    //         } else {
    //           progress(e.lengthComputable, e.loaded, e.total);
    //         }
    //       },
    //     })
    //       .then((response) => {
    //         // console.log(response);
    //         this.countupload++;
    //         // passing the file id to FilePond
    //         if (response.data.status === "OK") {
    //           this.checkstatusupload = true;
    //           load();
    //         } else if (response.data.errorMessage === "Your space not enough") {
    //           this.handleError(error, {
    //             message: this.$t("dragdrop.yourspacenotenough"),
    //             code: 500,
    //           });
    //           this.createprogress = false;
    //         } else if (
    //           response.data.errorMessage === "File Size More Than 5 GB"
    //         ) {
    //           this.handleError(error, {
    //             message: this.$t("dragdrop.morefivegb"),
    //             code: 500,
    //           });
    //           this.createprogress = false;
    //         } else {
    //           this.handleError(error, {
    //             // message: response.data.errorMessage,
    //             message: this.$t("dragdrop.cannotdetailupload"),
    //             code: 500,
    //           });
    //           this.createprogress = false;
    //         }
    //       })
    //       .catch((thrown) => {
    //         console.log(thrown);
    //         if (this.axios.isCancel(thrown)) {
    //           console.log("Request canceled", thrown.message);
    //         } else {
    //           this.handleError(error, thrown);
    //         }
    //         this.createprogress = false;
    //       });
    //   }
    //   // Setup abort interface
    //   return {
    //     abort: () => {
    //       this.createprogress = false;
    //       source.cancel("Operation canceled by the user.");
    //       abort();
    //     },
    //   };
    // },
    cleardata() {
      console.log("testclaer");
      this.count_cancel = 0;
      this.datachunkwaiting = [];
      this.chunkwaiting = [];
      this.checkopenchunk = false;
      this.statusuploading = false;
      this.myFiles = [];
      this.files = [];
      this.createprogress = false;
      this.uploadPercentage = 0;
      this.listuploadfile = [];
      this.selectingimportFiles = false;
      this.fileImport = null;
      this.percentfileuploading = 0;
      this.arraydataupload = [];
      this.countduplicate = 0;
      this.countupload = 0;
      this.fileduplicate = "";
      this.listfileduplicate = [];
      this.checkstatusduplicate = false;
      this.openalertduplicatefile = false;
      this.totalstackfile = 0;
      this.temparraydataupload = [];
      this.stackfileloading = false;
      this.userdata = {};
      this.uniqueidfile = 0;
      this.datahashtag = [];
      this.hashtag = "";
      this.checkhashtag = false;
      this.checkEncrypt = false;
    },
  },
  mounted() {
    this.cleardata();
    this.createprogress = false;
    // document.querySelector("#dropzoneuploadfiles").style.border = "4px dashed " + this.color.theme;
    // document.querySelector("#dropzoneuploadfiles").style.background = this.color.BG;
    // document.querySelector("#layouthoveruploadfiles").style.background = this.color.BG;
    // document.querySelector("#layouthoveruploadfiles").style.opacity = "1";
  },
  beforeDestroy() {
    this.removeEventUploadfiles();
    this.cleardata();
    this.createprogress = false;
  },
};
</script>
<style scoped>
.v-input.textFieldClass input {
  font-size: 14px;
  max-height: 90px;
  padding-bottom: 11px;
  padding-top: 11px;
}
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.checkboxStyle .v-label {
  font-size: 14px;
  color: black;
}
.textFieldClass .v-label {
  font-size: 14px;
  display: flex !important;
}
#titleTextUploadtitle {
  color: black;
  font-size: 18px;
  line-height: 24px;
}
#titleTextUploadsubtitle {
  color: red;
  font-size: 16px;
  line-height: 24px;
}
#inputuploadfiles {
  border-radius: 23px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}
</style>
